import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, EmptyState, Link, Typography } from '@getgo/chameleon-web-react-wrapper';
import {
  EncryptionOutlinedIcon,
  ResolveEndpointProtectionOutlinedIcon,
} from '@getgo/chameleon-icons/react';

import { ExternalPath } from 'app_constants/path';
import { navigateToDevicesPage } from 'components/Header/Header';
import environments from 'environments';
import { isStandardOrPremiumUser } from 'services/auth';
import { getUser } from 'services/user';
import { getValidatedDevicesFilter } from 'shared/hooks/useSettings';
import { navigate, openNewTab } from 'synapse-js/events/navigation';

import { getUpdatedFilters } from '../../AntivirusTable';
import { TDeviceFilter } from '../../types';

const getEmptyStateIcon = (selectedFilter?: TDeviceFilter['trackingProp']) => {
  return selectedFilter === 'all_devices_list' ? (
    <EncryptionOutlinedIcon />
  ) : selectedFilter === 'GTRe_EPP_devices_list' ? (
    <ResolveEndpointProtectionOutlinedIcon />
  ) : (
    <EncryptionOutlinedIcon />
  );
};

export const DevicesListEmptyState = React.memo(
  ({
    filters,
    setFilters,
    resetDevicesTable,
  }: {
    filters: TDeviceFilter[];
    setFilters: React.Dispatch<React.SetStateAction<TDeviceFilter[]>>;
    resetDevicesTable: () => void;
  }) => {
    const { t } = useTranslation('DevicesListEmptyState');

    const companyId = getUser().company.id;
    const gotoAdminUrl = environments.gotoAdminMicroTransactionUrl.replace(
      '{COMPANY_ID}',
      companyId
    );
    const openGotoAdminMicroTransaction = () => openNewTab(gotoAdminUrl);

    const navigateToAllDevicesFilterTab = () => {
      const filter = getValidatedDevicesFilter('all_devices_list');
      const updatedFilters = getUpdatedFilters(filters, filter.idx);

      setFilters(updatedFilters);
      resetDevicesTable();
    };

    const openSupportLink = () => openNewTab(ExternalPath.SUPPORT_ANTIVIRUS);
    const openSalesLink = () => openNewTab(ExternalPath.SALES);
    const openECommerce = () => navigate(ExternalPath.ECOMMERCE);

    const selectedFilter = filters.find((filter) => filter.selected)?.trackingProp;

    const actions = (
      <>
        {isStandardOrPremiumUser() ? (
          <>
            <Button
              variant="primary"
              size="medium"
              onClick={
                selectedFilter === 'all_devices_list'
                  ? navigateToDevicesPage
                  : selectedFilter === 'GTRe_EPP_devices_list'
                  ? navigateToAllDevicesFilterTab
                  : navigateToDevicesPage
              }
            >
              {selectedFilter === 'all_devices_list'
                ? t('manage-devices-button')
                : selectedFilter === 'GTRe_EPP_devices_list'
                ? t('install-on-devices-button')
                : t('manage-devices-button')}
            </Button>
            <Link variant="body-small" onClick={openSupportLink}>
              {selectedFilter === 'all_devices_list'
                ? ''
                : selectedFilter === 'GTRe_EPP_devices_list'
                ? t('learn-more')
                : ''}
            </Link>
          </>
        ) : (
          <>
            <Button
              variant="primary"
              size="medium"
              onClick={
                selectedFilter === 'all_devices_list'
                  ? openECommerce
                  : selectedFilter === 'GTRe_EPP_devices_list'
                  ? openGotoAdminMicroTransaction
                  : openECommerce
              }
            >
              {selectedFilter === 'all_devices_list'
                ? t('customize-plan-button')
                : selectedFilter === 'GTRe_EPP_devices_list'
                ? t('get-started-button')
                : t('customize-plan-button')}
            </Button>
            <Link variant="body-small" onClick={openSalesLink}>
              {selectedFilter === 'all_devices_list'
                ? ''
                : selectedFilter === 'GTRe_EPP_devices_list'
                ? t('learn-more-about-epp-link')
                : t('contact-sales-link')}
            </Link>
          </>
        )}
      </>
    );

    return (
      <>
        {isStandardOrPremiumUser() ? (
          <>
            <EmptyState
              className="empty-state"
              icon={getEmptyStateIcon(selectedFilter)}
              size="medium"
              title={
                selectedFilter === 'all_devices_list'
                  ? t('add-devices-title')
                  : selectedFilter === 'GTRe_EPP_devices_list'
                  ? t('gtre-epp-add-devices-title')
                  : t('add-devices-title')
              }
              actions={actions}
            >
              <Typography variant="body-small" color="type-color-secondary">
                {selectedFilter === 'all_devices_list'
                  ? t('add-devices-body')
                  : selectedFilter === 'GTRe_EPP_devices_list'
                  ? t('gtre-epp-add-devices-body')
                  : t('add-devices-body')}
              </Typography>
            </EmptyState>
          </>
        ) : (
          <>
            <EmptyState
              className="empty-state"
              icon={getEmptyStateIcon(selectedFilter)}
              size="medium"
              title={
                selectedFilter === 'all_devices_list'
                  ? t('basic-user-upgrade-title')
                  : selectedFilter === 'GTRe_EPP_devices_list'
                  ? t('gtre-epp-buy-add-on-title')
                  : t('basic-user-upgrade-title')
              }
              actions={actions}
            >
              <Typography variant="body-small" color="type-color-secondary">
                {selectedFilter === 'all_devices_list'
                  ? t('basic-user-upgrade-body')
                  : selectedFilter === 'GTRe_EPP_devices_list'
                  ? t('gtre-epp-add-devices-body')
                  : t('basic-user-upgrade-body')}
              </Typography>
            </EmptyState>
          </>
        )}
      </>
    );
  }
);

DevicesListEmptyState.displayName = 'DevicesListEmptyState';
