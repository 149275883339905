import { policyApi } from "services/api";
import { AVPolicyInfo, PolicyDto, PolicyType } from 'types';
import { logErrorWithDescription } from "services/app/telemetry/telemetry";
import { useQuery } from "@tanstack/react-query";
import { isDefined } from "utils";


export function usePoliciesByType(type: PolicyType): {
    isPolicyLoading: boolean;
    policies?: AVPolicyInfo[];
} {
    const { data: policyInfo, isLoading: isPolicyLoading } = useQuery(['policy', { type }],
        policyApi.getPolicies,
        {
            onError: logErrorWithDescription('Error: fetching policy info'),
        }
    );

    return {
        isPolicyLoading,
        policies: policyInfo?.map((policy: PolicyDto) => mapPolicy(policy)).filter(isDefined)
    };
}

export const mapPolicy = (policyDto: PolicyDto): AVPolicyInfo | undefined => {
    if (!policyDto.path || !policyDto.isActive) {
        return undefined;
    }

    return {
        id: policyDto.id,
        name: policyDto.name,
        devices: policyDto.devices
    };
};

