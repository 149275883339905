import { useQuery, useQueryClient } from '@tanstack/react-query';
import { companyApi } from 'services/api';
import { logErrorWithDescription } from 'services/app/telemetry/telemetry';

export type CompanyLicenses = {
  avLicensesProvisioned: number;
  avLicensesInstalled: number;
};

export const useLicenses = (): {
  isLicensesLoading: boolean;
  companyLicenses?: CompanyLicenses;
} => {
  const { data: companyLicenses, isLoading: isLicensesLoading } = useQuery(
    ['licenses'],
    companyApi.getCompanyLicenses,
    {
      onError: logErrorWithDescription('Error: fetching company licenses'),
      refetchInterval: 5000,
    }
  );

  return {
    isLicensesLoading,
    companyLicenses,
  };
};

export const useInvalidateLicenses = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(['licenses']);
};
