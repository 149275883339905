import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Link, IconButton } from '@getgo/chameleon-web-react-wrapper';
import { ArrowLeftFilledIcon } from '@getgo/chameleon-icons/react';
import './Header.scss';
import { ExternalPath } from 'app_constants/path';
import { navigate } from 'synapse-js/events/navigation';

export interface HeaderProps {
  HeaderControls?: ComponentType;
}

export const navigateToDevicesPage = () => navigate('', ExternalPath.DEVICES);

const Header = ({ HeaderControls }: HeaderProps) => {
  const { t } = useTranslation('Header');

  return (
    <div className="page-header">
      <div className="page-header__controls-wrapper">
        <div className="breadcrumbs">
          <IconButton variant="primary" size="medium" onClick={navigateToDevicesPage}>
            <ArrowLeftFilledIcon />
          </IconButton>
          <Link variant="body-small" className="breadcrumbs__link" onClick={navigateToDevicesPage}>
            {t('breadcrumb')}
          </Link>
        </div>
        {HeaderControls && (
          <div className="page-header__controls">
            <HeaderControls />
          </div>
        )}
      </div>
      <Typography tag="h1" variant="heading-medium" className="page-header__heading">
        {t('headline')}
      </Typography>
    </div>
  );
};

export default Header;
