import { DEFAULT_LANGUAGE, DEFAULT_THEME, THEME_DARK, THEME_LIGHT } from 'app_constants';
import { AppProps } from 'components/CustomElement/CustomElement';
import { createContext, ReactNode } from 'react';

type AppSettingContextType = {
  theme: typeof THEME_LIGHT | typeof THEME_DARK;
  language: string;
  options: string;
  navigationRoute: string;
};

export const defaultAppSetting: AppSettingContextType = {
  theme: DEFAULT_THEME,
  language: DEFAULT_LANGUAGE,
  options: '',
  navigationRoute: ''
};

export const AppSettingContext = createContext<AppSettingContextType>(defaultAppSetting);

type AppSettingProviderProps = {
  children: ReactNode;
  settings: AppProps;
};

const AppSettingProvider = ({ children, settings }: AppSettingProviderProps) => {
  const { theme, language, options, ['navigation-route']: navigationRoute } = settings;

  const contextValue = Object.fromEntries(
    Object.entries({ theme, language, options, navigationRoute }).filter(([key]) => key)
  );

  return (
    <AppSettingContext.Provider value={{ ...defaultAppSetting, ...contextValue }}>
      {children}
    </AppSettingContext.Provider>
  );
};

export default AppSettingProvider;
