import { useCallback } from 'react';
import { CheckmarkCircleFilledIcon, LoaderIcon } from '@getgo/chameleon-icons/react';
import { BannerStates } from 'shared/contexts/commands';
import useBackgroundProcessBanner from 'observables/banner';
import BackgroundProcessControls from './Controls';
import BackgroundProcessTitles from './Titles';
import './BackgroundProcessBanner.scss';

const BackgroundProcessBanner = () => {
  const { state } = useBackgroundProcessBanner();

  const { bannerState } = state;

  const renderIcon = useCallback(() => {
    if (bannerState === BannerStates.RUNNING)
      return (
        <div className="process-banner__loader-icon">
          <LoaderIcon />
        </div>
      );
    if (bannerState === BannerStates.COMPLETED) return <CheckmarkCircleFilledIcon />;

    return null;
  }, [bannerState]);

  if (bannerState === BannerStates.CLOSED) return null;

  return (
    <div className={`process-banner process-banner--${bannerState}`}>
      <div className="process-banner__content">
        {renderIcon()}
        <BackgroundProcessTitles />
      </div>
      <BackgroundProcessControls />
    </div>
  );
};

export default BackgroundProcessBanner;
