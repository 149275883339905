import environment from 'environments';
import { getSynapseToken } from 'services/auth';
import HttpRequestError from './HttpRequestError';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type Headers = Record<string, string>;

type RequestParams<RequestBody> = {
  method: HTTPMethod;
  baseUrl?: string;
  url: string;
  body?: RequestBody;
  headers?: Headers;
  token?: string;
};

const createRequest = <RequestBody>(params: RequestParams<RequestBody>): Request => {
  const { method, url, baseUrl, body, headers, token } = params;

  const apiUrl = baseUrl ? baseUrl : environment.frontendApiUrl;

  const authToken = token || getSynapseToken();

  const input: RequestInfo = `${apiUrl}${url}`;
  const init: RequestInit = {
    mode: 'cors',
    method: method.toUpperCase(),
    body: typeof body === 'object' ? JSON.stringify(body) : undefined,
    headers: {
      ...environment.HTTPheaders,
      ...headers,
      Authorization: `Bearer ${authToken}`,
    },
  };

  return new Request(input, init);
};

export const sendRequest = async <RequestBody, ResponseBody>(
  requestParams: RequestParams<RequestBody>
): Promise<ResponseBody> => {
  const request = createRequest<RequestBody>(requestParams);

  const response = await fetch(request);

  if (!response.ok) {
    throw new HttpRequestError(`Network error with code: ${response.status}`, response);
  }

  // Prevent error when there is no body in response (instead body is undefined)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  return await response.json().catch(() => {});
};

export const get = async <ResponseBody>(url: string): Promise<ResponseBody> =>
  await sendRequest<null, ResponseBody>({ method: 'GET', url });

export const post = async <RequestBody, ResponseBody>(
  url: string,
  body?: RequestBody,
  baseUrl?: string
): Promise<ResponseBody> =>
  await sendRequest<RequestBody, ResponseBody>({ method: 'POST', baseUrl, url, body });

export const put = async <RequestBody, ResponseBody>(
  url: string,
  body?: RequestBody
): Promise<ResponseBody> =>
  await sendRequest<RequestBody, ResponseBody>({ method: 'PUT', url, body });

export const patch = async <RequestBody, ResponseBody>(
  url: string,
  body?: RequestBody
): Promise<ResponseBody> =>
  await sendRequest<RequestBody, ResponseBody>({ method: 'PATCH', url, body });

export const remove = async <RequestBody, ResponseBody>(
  url: string,
  body?: RequestBody
): Promise<ResponseBody> =>
  await sendRequest<RequestBody, ResponseBody>({ method: 'DELETE', url, body });
