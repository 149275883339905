import { useSyncExternalStore } from 'react';
import { Command } from 'app_constants/commands';
import { BannerStates } from 'shared/contexts/commands';
import { BANNER_DISPLAY_TIME } from 'app_constants';
import { BehaviorSubject, filter, delay } from 'rxjs';

type BannerState = {
  bannerState: BannerStates;
  command: Command;
};

const initialState = {
  bannerState: BannerStates.CLOSED,
  command: Command.QUICK_SCAN,
};

export const bannerSubject$ = new BehaviorSubject<BannerState>(initialState);

const setClosed = () => bannerSubject$.next(initialState);

const setRunning = (command: Command) =>
  bannerSubject$.next({
    ...bannerSubject$.value,
    bannerState: BannerStates.RUNNING,
    command,
  });

const setCompleted = () =>
  bannerSubject$.next({
    ...bannerSubject$.value,
    bannerState: BannerStates.COMPLETED,
  });

const shouldCloseBanner = (bannerSubjectState: BannerState) =>
  bannerSubjectState.bannerState === BannerStates.RUNNING ||
  bannerSubjectState.bannerState === BannerStates.COMPLETED;

bannerSubject$.pipe(filter(shouldCloseBanner), delay(BANNER_DISPLAY_TIME)).subscribe(setClosed);

const useBackgroundProcessBanner = () => {
  const bannerSubjectState = useSyncExternalStore(
    (listener: () => void) => {
      const subscription = bannerSubject$.subscribe(listener);

      return () => subscription.unsubscribe();
    },
    () => bannerSubject$.value
  );

  return {
    state: bannerSubjectState,
    setClosed,
    setRunning,
    setCompleted,
  };
};

export default useBackgroundProcessBanner;
