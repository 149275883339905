export const CUSTOM_TAG_NAME = 'antivirus-app';
export const THEME_ATTRIBUTE = 'theme';
export const JWT_TOKEN_ATTRIBUTE = 'jwt-token';
export const LANGUAGE_ATTRIBUTE = 'language';
export const OPTIONS_ATTRIBUTE = 'options';
export const NAVIGATION_ROUTE_ATTRIBUTE  = 'navigation-route';
export const OBSERVABLE_ATTRIBUTES = [
  THEME_ATTRIBUTE,
  LANGUAGE_ATTRIBUTE,
  JWT_TOKEN_ATTRIBUTE,
  OPTIONS_ATTRIBUTE,
  NAVIGATION_ROUTE_ATTRIBUTE,
];
export const SKIN_GOTO_RESOLVE_ATTRIBUTE = 'gotoresolve';
export const LOCALHOST_URL = 'http://localhost:3000';
export const APP_INSIGHTS_NAME_PREFIX = 'antivirusManagement';
export const ENVIRONMENTS = {
  DEV: 'Development',
  STAGE: 'Stage',
  PROD: 'Prod',
};
export const BANNER_DISPLAY_TIME = 5000;

export const Integration = {
  OPSWAT: 'opswat',
  BITDEFENDER: 'bitdefender',
} as const;

export const Platform = {
  WINDOWS: 'Windows',
  WIN: 'Win',
} as const;

export const SupportedOS = {
  WINDOWS_10: 'Windows 10',
  WINDOWS_11: 'Windows 11',
  WINDOWS_SERVER: 'Windows Server',
  WINDOWS_SERVER_DATACENTER_EDITION: 'Windows Server Datacenter Edition',
} as const;

export const SupportedPlatform = {
  WINDOWS: 'Windows',
  Win: 'Win',
} as const;
