export const ENABLE_ENDPOINT_PROTECTION = 'Enable_AVmanagement';
export const RUN_FULL_SCAN = 'Full_AV_scan';
export const RUN_QUICK_SCAN = 'Quick_AV_scan';
export const UPDATE_VIRUS_DEFINITION = 'Update_virus_definition';
export const ENABLE_REALTIME_PROTECTION = 'Turn_on_real-time_protection';
export const ENABLE_FIREWALL = 'Turn_on_firewall';
export const DISABLE_FIREWALL = 'Turn_off_firewall';
export const VIEW_ANTIVIRUS_PAGE = 'Antivirus_page_viewed';
export const FILTER_AV_LIST = 'Filter_av_list';
export const OPEN_LICENSE_MODAL = 'Open_license_modal';
export const CLOSE_LICENSE_MODAL = 'Close_license_modal';
export const MANAGE_LICENSES = 'Manage_licenses';
export const CONTACT_SALES = 'Contact_sales';
export const INSTALL_ENDPOINT_PROTECTION = 'Install_endpoint_protection';
