import { TUserData, TUser } from 'types';

let _user: TUser | null = null;

export const setUser = (data: TUserData): void => {
  _user = {
    id: data.sub,
    fullName: data.name,
    email: data.email,
    company: { id: data.companyId, name: data.companyName },
    capabilities: data.capabilities,
  };
};

export const getUser = (): TUser => {
  if (!_user) {
    throw new Error('User data is missing. Synapse token is not setted');
  }

  return _user;
};

export const hasCapability = (name?: string): boolean => {
  if (!name) return false;

  const { capabilities } = getUser();

  return capabilities.includes(name);
};
