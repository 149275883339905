const loaderWrapperStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  margin: '0 auto',
  transform: 'translate3d(-25%, -50%, 0)',
  width: '100px',
  height: '100px',
  boxSizing: 'border-box',
  display: 'flex',
} as const;

const svgStyles = {
  width: '50px',
  maxWidth: '100px',
  animation: '1.8s linear infinite svg--animation',
} as const;

const circleStyles = {
  fill: 'transparent',
  stroke: 'var(--goto-link)',
  strokeWidth: 10,
  strokeLinecap: 'round',
  strokeDasharray: 283,
  strokeDashoffset: 280,
  transformOrigin: '50% 50%',
  animation: '1.4s ease-in-out infinite both circle--animation',
} as const;

const animationKeyframes = `
@keyframes svg--animation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle--animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0deg);
  }
  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }
  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}`;

const Loader = () => {
  return (
    <div style={loaderWrapperStyles}>
      <style>{animationKeyframes}</style>
      <svg style={svgStyles} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle style={circleStyles} cx="50" cy="50" r="45"></circle>
      </svg>
    </div>
  );
};

export default Loader;
