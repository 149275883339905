import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { BannerStates } from 'shared/contexts/commands';
import { Command } from 'app_constants/commands';
import useBackgroundProcessBanner from 'observables/banner';
import useSelectionSummaryData from 'observables/selectionSummaryData';
import './BackgroundProcessBanner.scss';

const BackgroundProcessTitles = () => {
  const { t } = useTranslation('BackgroundProcessBanner');
  const { state } = useBackgroundProcessBanner();
  const { bannerState, command } = state;
  const { state: selectionSummaryData } = useSelectionSummaryData();

  const titles = useMemo(() => {
    const actions: { [key in Command]: string } = t('running-actions', { returnObjects: true });

    return {
      [BannerStates.COMPLETED]: t('scan-finished'),
      [BannerStates.RUNNING]: actions[command!],
    };
  }, [command, t]);

  const descriptions = useMemo(
    () => ({
      [BannerStates.COMPLETED]: t('scan-finished-description', {
        count: selectionSummaryData?.devicesSelected,
      }),
      [BannerStates.RUNNING]: t('running-description-default'),
    }),
    [selectionSummaryData, t]
  );

  if (bannerState === BannerStates.CLOSED) return null;

  return (
    <div className="process-banner__titles">
      <Typography className="process-banner__title" variant="body-medium-strong">
        {titles[bannerState]}
      </Typography>
      <Typography
        className="process-banner__description"
        variant="body-small"
        color="type-color-secondary"
      >
        {descriptions[bannerState]}
      </Typography>
    </div>
  );
};

export default BackgroundProcessTitles;
