import { TUser, TPageView, TTrackingEvent, TCustomProperties, ITelemetry } from 'types';
import type * as SentryNamespace from '@sentry/browser';

type TSegmentEvent = {
  name?: string;
  payload: {
    [key: string]: string | number;
  };
};

type TSegmentUserProperties = {
  companyId: string;
};

class SegmentTelemetry implements ITelemetry {
  private _segment: SegmentAnalytics.AnalyticsJS | null;
  private _sentry: typeof SentryNamespace | null;
  private _segmentUserProperties: TSegmentUserProperties;
  private _deferredEvents: TSegmentEvent[] = [];
  private _deferredPageViews: TSegmentEvent[] = [];
  private _deferredErrors: { error: Error; extra: Record<string, unknown> | undefined }[] = [];

  constructor(user: TUser) {
    const { company } = user;

    this._segment = null;
    this._sentry = null;

    this._segmentUserProperties = { companyId: company.id };

    this._handleDefferedEvents();
  }

  private _handleDefferedEvents(): void {
    const handler = (event: unknown) => {
      if ((event as CustomEvent).detail.type === 'analytics-js-loaded') {
        this._segment = window.document.defaultView!.analytics;
        this._sentry = window.document.defaultView!.Sentry;
        this._sentry.setTag('gtr-app', 'antivirus');

        this._deferredEvents.forEach(({ name, payload }) => this._segment!.track(name!, payload));
        this._deferredEvents = [];

        this._deferredPageViews.forEach(({ name, payload }) =>
          this._segment!.page(name, name, payload)
        );
        this._deferredPageViews = [];

        this._deferredErrors.forEach((errorItem) =>
          this._sentry!.captureException(errorItem.error, { extra: errorItem.extra })
        );
        this._deferredErrors = [];
      }
    };

    window.addEventListener('synapse-app-event', (event: unknown) => {
      handler(event);
      window.removeEventListener('synapse-app-event', handler);
    });
  }

  trackPageView(pageView: TPageView) {
    if (this._segment) {
      this._segment.page(pageView?.name, pageView?.name, {
        ...this._segmentUserProperties,
        url: pageView!.uri,
      });
    } else {
      this._deferredPageViews.push({ name: pageView?.name, payload: this._segmentUserProperties });
    }
  }

  trackEvent({ name }: TTrackingEvent, customProperties?: TCustomProperties): void {
    const properties = {
      ...this._segmentUserProperties,
      ...customProperties,
    };

    if (this._segment) {
      this._segment.track(name, properties);
    } else {
      this._deferredEvents.push({ name, payload: properties });
    }
  }

  logError(error: string | Error, extra?: Record<string, unknown>): void {
    const wrappedError = typeof error === 'string' ? new Error(error) : error;
    if (this._sentry) {
      this._sentry.captureException(wrappedError, { extra });
    } else {
      this._deferredErrors.push({ error: wrappedError, extra });
    }
  }
}

export default SegmentTelemetry;
