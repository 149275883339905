import { TDevice } from 'types';
import { Command } from 'app_constants/commands';

export enum BannerStates {
  CLOSED = 'closed',
  RUNNING = 'running',
  COMPLETED = 'completed',
}

export enum Actions {
  SET_CLOSED = 'SET_CLOSED',
  SET_RUNNING = 'SET_RUNNING',
  SET_COMPLETED = 'SET_COMPLETED',
  SET_MODAL_OPENED = 'SET_MODAL_OPENED',
  SET_MODAL_CLOSED = 'SET_MODAL_CLOSED',
}

export type State = {
  devices: TDevice[];
  scans: [];
  bannerState: BannerStates;
  action: Command;
};

export type Action = {
  type: string;
  payload?: unknown;
};

export const initialState: State = {
  bannerState: BannerStates.CLOSED,
  action: '' as Command,
  devices: [],
  scans: [],
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_CLOSED:
      return initialState;
    case Actions.SET_RUNNING:
      return { ...state, bannerState: BannerStates.RUNNING, action: action.payload as Command };
    case Actions.SET_COMPLETED:
      return { ...state, bannerState: BannerStates.COMPLETED };
    default:
      return state;
  }
};

export default reducer;
