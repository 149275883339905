import environments from 'environments';
import http from 'services/app/http';
import { logError } from 'services/app/telemetry/telemetry';

export interface ReportJobParams {
  remoteExecutionJobId: string;
  appUpdateInfo?: AppUpdateInfo | null;
}

export interface AppUpdateInfo {
  deviceIdToAppUpdate: { [key: string]: AppUpdate[] };
}

export interface AppUpdate {
  id: string;
  name: string;
  source: string;
  targetVersion: string;
}

export class InstallJobReportService {
  async reportJob(params: ReportJobParams): Promise<void> {
    try {
      const url = `/jobs`;
      await http.post(url, params, environments.remoteExecutionJobReportApiUrl);
    } catch (e) {
      logError(e as Error);
    }
  }
}
