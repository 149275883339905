import React, { useCallback, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@getgo/chameleon-web-react-wrapper';
import { useModal } from 'shared/hooks';
import {
  LastScanReport,
  CreateTicket,
  LicenseWarning,
  InstallationStalled,
} from 'components/Modal';

type TModals = {
  [key: string]: {
    Content: FC;
    Actions: FC;
  };
};

export const MODAL: TModals = {
  LastScanReportModal: LastScanReport,
  CreateTicketModal: CreateTicket,
  LicenseWarning: LicenseWarning,
  InstallationStalledModal: InstallationStalled,
};

const Modal = () => {
  const { state, close } = useModal();
  const { name, opened } = state;

  const { t } = useTranslation(name);

  const onAppEvent = useCallback(
    (event: unknown) => {
      if ((event as CustomEvent).detail.type === 'zero-trust-signature-key-modal-triggered') {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    if (opened) {
      window?.addEventListener('synapse-app-event', onAppEvent);
    }

    return () => {
      window?.removeEventListener('synapse-app-event', onAppEvent);
    };
  }, [onAppEvent, opened]);

  return (
    <Dialog
      open={opened}
      closable
      onClosed={close}
      size={name === 'LicenseWarning' ? 'large' : name === 'LastScanReportModal' ? 'xlarge' : 'medium'}
      title={t('title')}
      actions={React.createElement(MODAL[name].Actions)}
    >
      {React.createElement(MODAL[name].Content)}
    </Dialog>
  );
};

export default Modal;
