import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'translations/en-US.json';
import es from 'translations/es-ES.json';
import de from 'translations/de-DE.json';
import fr from 'translations/fr-FR.json';
import it from 'translations/it-IT.json';
import pt from 'translations/pt-BR.json';

export type { TFunction } from 'i18next';

const DEFAULT_LANGAUGE = 'en-US';

const options: InitOptions = {
  debug: false,
  lng: DEFAULT_LANGAUGE,
  fallbackLng: DEFAULT_LANGAUGE,
  resources: {
    'en-US': en,
    'en-EN': en,
    'es-ES': es,
    'de-DE': de,
    'fr-FR': fr,
    'it-IT': it,
    'pt-BR': pt,
  },
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(options);

export default i18n;
