import { createContext, ReactNode, useReducer, Dispatch } from 'react';
import { Modal } from 'shared/components';
import modalReducer, {
  initialState as modalInitialState,
  Action as ModalAction,
} from './modal-reducer';

export type TModalContext = [typeof modalInitialState, Dispatch<ModalAction>];

export const ModalContext = createContext<TModalContext>({} as TModalContext);

const RunScanProvider = ({ children }: { children: ReactNode }) => {
  const modalContext = useReducer(modalReducer, modalInitialState);
  const [{ opened }] = modalContext;

  return (
    <ModalContext.Provider value={modalContext}>
      {opened && <Modal />}
      {children}
    </ModalContext.Provider>
  );
};

export default RunScanProvider;
