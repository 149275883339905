import { TStringObject } from 'types/common';

export const DEVICES_LIMIT = 5;
export const DEVICE_ACTION = 'DeviceAction';
export const QUICK_SCAN_MODAL = 'QuickScanModal';
export const FULL_SCAN_MODAL = 'FullScanModal';
export const UPDATE_DEFINITIONS_MODAL = 'UpdateDefinitionsModal';
export const ENABLE_RTP_MODAL = 'EnableRTPModal';
export const ENABLE_FIREWALL_MODAL = 'EnableFirewallModal';
export const DISABLE_FIREWALL_MODAL = 'DisableFirewallModal';
export const LAST_SCAN_REPORT_MODAL = 'LastScanReportModal';
export const CREATE_TICKET_MODAL = 'CreateTicketModal';
export const INSTALLATION_STALLED = 'InstallationStalledModal';

export const MODAL_ACTIONS: TStringObject = {
  [QUICK_SCAN_MODAL]: 'quick-scan',
  [FULL_SCAN_MODAL]: 'full-scan',
  [UPDATE_DEFINITIONS_MODAL]: 'update-definitions',
  [ENABLE_RTP_MODAL]: 'enable-rtp',
  [ENABLE_FIREWALL_MODAL]: 'enable-firewall',
  [DISABLE_FIREWALL_MODAL]: 'disable-firewall',
  [LAST_SCAN_REPORT_MODAL]: 'last-scan-report',
  [CREATE_TICKET_MODAL]: 'create-ticket',
};

export const HELPDESK_CAPABILITY = 'helpdesk';
export const ADMIN_CAPABILITY = 'admin';
