import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AppSettingContext } from 'shared/contexts/app_setting';
import { TDevice, TCommandApiService, TAppOptions } from 'types';
import { requireSignature } from 'utils/requireSignature';
import useBackgroundProcessBanner from 'observables/banner';
import { Command } from 'app_constants/commands';
import { logErrorWithDescription } from 'services/app/telemetry/telemetry';

type TExecutor = {
  devices: TDevice[];
  groupIds: (string | null)[];
  cb: TCommandApiService;
};

const useZTSignature = () => {
  const { options } = useContext(AppSettingContext);
  const parsedOptions: TAppOptions = options && JSON.parse(options);
  const { setRunning } = useBackgroundProcessBanner();
  const { mutate: sendRequest } = useMutation(
    ({ devices, groupIds, cb }: TExecutor) => cb({ devices, groupIds }),
    {
      onError: logErrorWithDescription('Error: Zero trust'),
    }
  );

  const open = useCallback(
    async ({
      devices,
      groupIds,
      command,
      action,
      modalText,
      onSuccess,
      onError,
    }: {
      devices: TDevice[];
      groupIds?: (string | null)[];
      command: TCommandApiService;
      action: string;
      modalText: string;
      onSuccess?: () => void;
      onError?: () => void;
    }) => {
      const signatureData = await requireSignature({
        zeroTrustEndpoint: parsedOptions.zeroTrustEndpoint,
        modalText,
        action,
      });

      if (signatureData) {
        sendRequest({ devices, groupIds: groupIds || [], cb: command });

        setRunning(action as Command);
        onSuccess && onSuccess();
      } else {
        onError && onError();
      }
    },
    [parsedOptions.zeroTrustEndpoint, sendRequest, setRunning]
  );

  return {
    openZTSignatureModal: open,
  };
};

export default useZTSignature;
