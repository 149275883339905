export enum Path {
  HOME = '/',
  ENABLE_PROTECTION = '/enable-protection',
  DEVICES_LIST = '/devices-list',
  GETSTARTED = '/get-started',
  POLICIES = '/policies',
  POLICY_DETAILS = '/policies/update',
  POLICIES_ALL = '/policies/*',
}

export enum ApiEndpoint {
  COMPANY = '/company',
  COMMANDS = '/commands',
  REPORTS = '/reports',
  LICENSES = '/company/licenses',
}

// the path is outside the app, i.e. our iframe
export enum ExternalPath {
  DEVICES = '/devices',
  DEVICE_DETAILS = '/devices/details',
  ALERTS = '/alerts',
  CREATE_TICKET = '/helpdesk/incidents/new',
  SUPPORT_ANTIVIRUS = 'https://support.goto.com/resolve/help/working-with-antivirus-in-goto-resolve',
  SALES = 'https://www.goto.com/it-management/resolve/try',
  ECOMMERCE = 'https://buy.goto.com/resolve/customize',
}
