import {
  DefaultUiFrameAdapter,
  SignatureKeyNotProvidedError,
  SignFlow,
} from 'goto-zero-trust-vault';
import i18n from 'services/app/i18n';
import { addToast, resetToasts } from 'synapse-js/events/toast';

export const requireSignature = async ({
  zeroTrustEndpoint,
  modalText,
  action,
}: {
  zeroTrustEndpoint: string;
  modalText: string;
  action: string;
}) => {
  const t = i18n.getFixedT(null, 'RequireSignature');

  try {
    const uiFrameAdapter = new DefaultUiFrameAdapter();
    const signFlow = SignFlow.Create(uiFrameAdapter, { baseUrl: zeroTrustEndpoint });
    const UTCTimestampSeconds = Math.floor(new Date().getTime() / 1000);
    const payload = new window.TextEncoder().encode(`${UTCTimestampSeconds}-av-${action}`); // Uint8Array
    const signature = await signFlow.sign(payload, modalText); // ArrayBuffer
    const vaultAccessToken = await signFlow.issueAccessToken();

    return {
      signature,
      signedData: payload,
      vaultAccessToken,
    };
  } catch (e) {
    if (
      !(e instanceof SignatureKeyNotProvidedError) &&
      !(e as Error).message.includes('Signature key is not set')
    ) {
      resetToasts();
      addToast(t('failed-to-fetch'), 'error', '');
    }
  }
};
