import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DataTable,
  DataTableBody,
  DataTableCellV2,
  DataTableRowV2,
  IconButton,
  Link,
  TooltipV2,
  Typography,
} from '@getgo/chameleon-web-react-wrapper';
import {
  AlertFilledIcon,
  BlockOutlinedIcon,
  CheckmarkCircleOutlinedIcon,
  CopyOutlinedIcon,
  DownloadFilledIcon,
  ProtectedFilledIcon,
  ScreenOutlinedIcon,
  SearchIcon,
} from '@getgo/chameleon-icons/react';
import { useModal, useZTSignature } from 'shared/hooks';
import { Loader } from 'shared/components';
import { commandApi, reportApi } from 'services/api';
import { getLocaleDateString } from 'utils/getLocaleDateString';
import { LAST_SCAN_REPORT_MODAL, MODAL_ACTIONS, QUICK_SCAN_MODAL } from 'app_constants/modal';
import {
  FirewallState,
  FirewallStatusToState,
  HealthStatus,
  RtpState,
  RtpStatus,
  RtpStatusToState,
} from 'app_constants/status';
import { isScanAvailable } from 'services/device-functionality';
import { ExternalPath } from 'app_constants/path';
import { navigate } from 'synapse-js/events/navigation';
import i18n from 'services/app/i18n';
import './LastScanReport.scss';
import { TDevice } from 'types';

const getFirewallState = (device: TDevice) =>
  device.firewall?.firewallState ??
  FirewallStatusToState.get(device.av?.firewallStatus) ??
  FirewallState.UNKNOWN;

const getRtpState = (device: TDevice) =>
  device?.av?.rtpState || RtpStatusToState.get(device?.av?.rtpStatus) || RtpState.UNKNOWN;

const Content = () => {
  const { t } = useTranslation(LAST_SCAN_REPORT_MODAL);
  const {
    state: { name, devices },
  } = useModal();

  const { data, isLoading } = useQuery(
    [MODAL_ACTIONS[LAST_SCAN_REPORT_MODAL], devices[0]],
    reportApi.getLastScan,
    {
      enabled: name === LAST_SCAN_REPORT_MODAL,
      select: ({ data }) => data,
    }
  );

  if (isLoading) return <Loader />;

  if (!data) {
    return (
      <div className="report-without-data">
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 4H26V6H6V36H4V4Z" fill="currentColor" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 23.5C11.5 16.3203 17.3203 10.5 24.5 10.5C31.6797 10.5 37.5 16.3203 37.5 23.5C37.5 30.6797 31.6797 36.5 24.5 36.5C21.2701 36.5 18.3153 35.3221 16.0418 33.3724L5.70718 43.707L4.29297 42.2928L14.6276 31.9582C12.6779 29.6847 11.5 26.7299 11.5 23.5ZM24.5 12.5C18.4249 12.5 13.5 17.4249 13.5 23.5C13.5 29.5751 18.4249 34.5 24.5 34.5C30.5751 34.5 35.5 29.5751 35.5 23.5C35.5 17.4249 30.5751 12.5 24.5 12.5Z"
            fill="currentColor"
          />
          <path d="M42 22V42H12V44H44V22H42Z" fill="currentColor" />
          <g clipPath="url(#search-static_svg__a)">
            <path d="M42 6H28V4H44V20H42V6Z" className="search-static_svg__brand-color" />
          </g>
          <defs>
            <clipPath id="search-static_svg__a">
              <rect width="16" height="16" fill="white" transform="translate(28 4)" />
            </clipPath>
          </defs>
        </svg>
        <Typography tag="h3" variant="heading-medium">
          {t('message.no-scans-yet')}
        </Typography>
        <Typography tag="span" variant="body-medium">
          {t('message.run-a-scan')}
        </Typography>
        <Button size="large" variant="primary" leadingIcon={<SearchIcon />}>
          {t('message.run-quick-scan')}
        </Button>
        <Link
          size="large"
          href="https://support.goto.com/resolve/help/working-with-antivirus-in-goto-resolve"
          target="_blank"
        >
          {t('message.learn-more')}
        </Link>
      </div>
    );
  }

  const needsUpdate = devices[0].av?.avHealthStatus === HealthStatus.DEFINITION_OUTDATED;

  return (
    <div className="report-data">
      <header>
        <div>
          <Typography tag="h4" variant="body-medium-strong">
            {t('result.result')}
          </Typography>
          {data.scanResult.threats?.length ? (
            <span className="inline icon-danger">
              <AlertFilledIcon />{' '}
              <Typography tag="p" variant="body-medium">
                {t('result.threat-found')}
              </Typography>
            </span>
          ) : needsUpdate ? (
            <span className="inline icon-warning">
              <DownloadFilledIcon />{' '}
              <Typography tag="p" variant="body-medium">
                {t('result.update-required')}
              </Typography>
            </span>
          ) : (
            <span className="inline icon-success">
              <ProtectedFilledIcon />{' '}
              <Typography tag="p" variant="body-medium">
                {t('result.protected')}
              </Typography>
            </span>
          )}
        </div>
        <div>
          <Typography tag="h4" variant="body-medium-strong">
            {t('scan-date-and-time')}
          </Typography>
          <Typography tag="p" variant="body-medium">
            {devices[0].av?.lastScanOn &&
            data.scanResult.endedOn &&
            !data.scanResult.endedOn.startsWith('0001') ? (
              <time dateTime={new Date(data.scanResult.endedOn).toISOString()}>
                {data && getLocaleDateString(data.scanResult.endedOn)}
              </time>
            ) : (
              <>{t('unknown')}</>
            )}
          </Typography>
        </div>
      </header>
      <main>
        <div>
          <Typography tag="h4" variant="body-medium-strong">
            {t('threats-found')}
          </Typography>
          {data.scanResult.threats?.length ? (
            <>
              <DataTable className="report-scan-results">
                <DataTableBody>
                  {data.scanResult.threats.map(({ threatName, path, remediation }, i) => (
                    <DataTableRowV2 key={`threat-${i}`}>
                      <DataTableCellV2>
                        <Typography tag="span" variant="body-medium-strong">
                          {threatName[0].toUpperCase() +
                            threatName
                              .slice(1)
                              .replace(/\/.*$/, '')
                              .replace(/\W/g, ' ')
                              .toLowerCase()}
                        </Typography>
                      </DataTableCellV2>
                      <DataTableCellV2>
                        <Typography tag="span" variant="body-medium">
                          {t('remediation.' + remediation)}
                        </Typography>
                      </DataTableCellV2>
                      <DataTableCellV2>
                        {path.length > 60 ? (
                          <TooltipV2
                            trigger={
                              <Typography tag="span" variant="body-medium-strong">
                                {shortenPath(path)}
                              </Typography>
                            }
                            zIndex={999}
                          >
                            {path}
                          </TooltipV2>
                        ) : (
                          <Typography tag="span" variant="body-medium-strong">
                            {path}
                          </Typography>
                        )}
                      </DataTableCellV2>
                      <DataTableCellV2>
                        <TooltipV2
                          trigger={
                            <IconButton
                              label={t('copy-path')}
                              onClick={() => navigator.clipboard.writeText(path)}
                            >
                              <CopyOutlinedIcon />
                            </IconButton>
                          }
                          zIndex={999}
                        >
                          {t('copy-path')}
                        </TooltipV2>
                      </DataTableCellV2>
                    </DataTableRowV2>
                  ))}
                </DataTableBody>
              </DataTable>
            </>
          ) : (
            <Typography tag="p" variant="body-medium">
              {t('message.scan-result-positive')}
            </Typography>
          )}
        </div>
        <div>
          <Typography tag="h4" variant="body-medium-strong">
            {t('protection')}
          </Typography>
          <DataTable className="report-protection-status">
            <DataTableBody>
              <DataTableRowV2>
                <DataTableCellV2>
                  <Typography tag="span" variant="body-medium-strong">
                    {t('definition-database')}
                  </Typography>
                </DataTableCellV2>
                <DataTableCellV2>
                  {needsUpdate ? (
                    <span className="inline icon-warning">
                      <DownloadFilledIcon />{' '}
                      <Typography tag="span" variant="body-medium">
                        {t('update-available')}
                      </Typography>
                    </span>
                  ) : (
                    <span className="inline icon-success">
                      <CheckmarkCircleOutlinedIcon />{' '}
                      <Typography tag="span" variant="body-medium">
                        {t('up-to-date')}
                      </Typography>
                    </span>
                  )}
                </DataTableCellV2>
              </DataTableRowV2>
              <DataTableRowV2>
                <DataTableCellV2>
                  <Typography tag="span" variant="body-medium-strong">
                    {t('firewall')}
                  </Typography>
                </DataTableCellV2>
                <DataTableCellV2>
                  <span
                    className={`inline ${
                      getFirewallState(devices[0]) === FirewallState.ENABLED
                        ? 'icon-success'
                        : 'icon-warning'
                    }`}
                  >
                    {getFirewallState(devices[0]) === FirewallState.ENABLED ? (
                      <CheckmarkCircleOutlinedIcon />
                    ) : (
                      <BlockOutlinedIcon />
                    )}{' '}
                    <Typography tag="span" variant="body-medium">
                      {t(getFirewallState(devices[0]).toLocaleLowerCase() ?? 'notsupported')}
                    </Typography>
                  </span>
                </DataTableCellV2>
              </DataTableRowV2>
              <DataTableRowV2>
                <DataTableCellV2>
                  <Typography tag="span" variant="body-medium-strong">
                    {t('RTP')}
                  </Typography>
                </DataTableCellV2>
                <DataTableCellV2>
                  <span
                    className={`inline ${
                      getRtpState(devices[0]) === RtpState.ENABLED ? 'icon-success' : 'icon-warning'
                    }`}
                  >
                    {getRtpState(devices[0]) === RtpStatus.ENABLED ? (
                      <CheckmarkCircleOutlinedIcon />
                    ) : (
                      <BlockOutlinedIcon />
                    )}{' '}
                    <Typography tag="span" variant="body-medium">
                      {t(
                        ['unknown', 'enabled', 'disabled'][getRtpState(devices[0])] ??
                          'notsupported'
                      )}
                    </Typography>
                  </span>
                </DataTableCellV2>
              </DataTableRowV2>
            </DataTableBody>
          </DataTable>
        </div>
      </main>
    </div>
  );
};

export const Actions = () => {
  const { t } = useTranslation(LAST_SCAN_REPORT_MODAL);
  const {
    state: { devices, isDisabledSubmit },
    close,
    disableSubmit,
    enableSubmit,
  } = useModal();
  const { openZTSignatureModal } = useZTSignature();

  const submit = useCallback(async () => {
    disableSubmit();
    openZTSignatureModal({
      devices,
      command: commandApi.quickScan,
      action: MODAL_ACTIONS[QUICK_SCAN_MODAL],
      modalText: i18n.getFixedT(null, QUICK_SCAN_MODAL)('caption', { count: devices.length }),
      onError: enableSubmit,
    });
  }, [disableSubmit, openZTSignatureModal, devices, enableSubmit]);

  const isQuickScanAllowed = isScanAvailable(devices[0]);

  return (
    <>
      <Button onClick={close}>{t('close-button')}</Button>
      <Button variant="neutral" disabled={isDisabledSubmit || !isQuickScanAllowed} onClick={submit}>
        {t('submit-button')}
      </Button>
      <Button
        variant="tertiary"
        leadingIcon={<ScreenOutlinedIcon />}
        className="button-extra-space"
        onClick={() => navigate(`/${devices[0].id}`, ExternalPath.DEVICE_DETAILS)}
      >
        {t('manage-device')}
      </Button>
    </>
  );
};

const shortenPath = (path: string) => {
  const parts = Array.from(path.match(/(^|[/\\])[^/\\]+/g) || []);
  if (parts.length <= 1) {
    return `…${path.slice(-60)}`;
  }

  const spacer = `${(parts.at(-1) || '/')[0]}…`;

  while (parts.join('').length > 60 && parts.length > 4) {
    if (parts.includes(spacer)) {
      const pos = (parts.length >> 1) - (parts[parts.length >> 1] === spacer ? 1 : 0);
      parts.splice(pos, 1);
    } else {
      parts.splice(parts.length >> 1, 1, spacer);
    }
  }
  const joinedPath = parts.join('');

  return joinedPath.length > 60 ? `…${joinedPath.slice(-60)}` : joinedPath;
};

export default Content;
