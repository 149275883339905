import environment from 'environments';
import http from 'services/app/http';
import { QueryFunction } from '@tanstack/react-query';
import { PolicyDto, PolicyType } from 'types';

const baseUrl = environment.automationFrontendApiUrl;

// format: /policy/${policyModule}/${platform}/${policyType}/v${version}
const typePolicyPathMap: Record<PolicyType, string> = {
  bitdefender: '/policy/antivirus/windows/bitdefender/v1',
};

export type PolicyQueryKey = readonly [string, { type: PolicyType }];
type TPolicyResult = PolicyDto[];

export const getPolicies: QueryFunction<TPolicyResult, PolicyQueryKey> = async ({ queryKey }) => {
  const urlSearchParams = new URLSearchParams();

  const type = queryKey[1].type;
  const path = typePolicyPathMap[type];
  if (!path) {
    throw new Error(`No policy path defined for ${type}`);
  }
  urlSearchParams.append('path', path);

  const urlWithParams = `/policies?${urlSearchParams.toString()}`;

  return http.sendRequest({
    method: 'GET',
    baseUrl,
    url: urlWithParams,
  });
};
