import { useContext, useCallback } from 'react';
import { TDevice } from 'types';
import { ModalContext } from 'shared/contexts/commands';
import { Actions } from 'shared/contexts/commands/modal-reducer';

const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) throw new Error('useModal should be used inside ModalContext');

  const [state, dispatch] = context;

  const open = useCallback(
    (modalName: string, devices?: TDevice[] | TDevice | null) => {
      let payload: TDevice[] = [];
      if (devices) {
        payload = Array.isArray(devices) ? devices : [devices];
      }

      dispatch({ type: Actions.SET_NAME, payload: modalName });
      dispatch({ type: Actions.SET_OPENED, payload });
    },
    [dispatch]
  );

  const openFromBanner = useCallback(
    (modalName: string, devices?: TDevice[] | TDevice | null) => {
      let payload: TDevice[] = [];
      if (devices) {
        payload = Array.isArray(devices) ? devices : [devices];
      }

      dispatch({ type: Actions.SET_NAME, payload: modalName });
      dispatch({ type: Actions.SET_OPENED_FROM_BANNER, payload });
    },
    [dispatch]
  );

  const close = useCallback(() => {
    dispatch({ type: Actions.SET_CLOSED });
  }, [dispatch]);

  const enableSubmit = useCallback(() => {
    dispatch({ type: Actions.SET_SUBMIT_ENABLED });
  }, [dispatch]);

  const disableSubmit = useCallback(() => {
    dispatch({ type: Actions.SET_SUBMIT_DISABLED });
  }, [dispatch]);

  const enableRequest = useCallback(() => {
    dispatch({ type: Actions.SET_REQUEST_ENABLED });
  }, [dispatch]);

  const disableRequest = useCallback(() => {
    dispatch({ type: Actions.SET_REQUEST_DISABLED });
  }, [dispatch]);

  return {
    state,
    dispatch,
    open,
    openFromBanner,
    close,
    enableSubmit,
    disableSubmit,
    enableRequest,
    disableRequest,
  };
};

export default useModal;
