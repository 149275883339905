import { useContext, useEffect } from 'react';
import { Button, LinearProgress, Typography } from '@getgo/chameleon-web-react-wrapper';
import { SettingsOutlinedIcon } from '@getgo/chameleon-icons/react';
import { useTranslation } from 'react-i18next';
import { AntivirusTable, BackgroundProcessBanner } from 'components';
import { withLayout } from 'shared/HOCs';
import { useModal } from 'shared/hooks';
import telemetry from 'services/app/telemetry';
import { Integration } from 'app_constants';
import { useLicenses } from 'shared/hooks/useLicenses/useLicenses';
import { LicenseIcon } from 'components/Modal/LicenseWarning';
import { AppSettingContext } from 'shared/contexts/app_setting';
import { TAppOptions } from 'types';
import { hasCapability } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { Path } from "../../app_constants/path";
import { ADMIN_CAPABILITY } from 'app_constants/modal';

const LicenseInformation = () => {
  const { t } = useTranslation('AntivirusPage');
  const { companyLicenses } = useLicenses();
  const { open } = useModal();

  return companyLicenses !== undefined ? (
    <Button
      size="large"
      className="license-button"
      variant="neutral"
      leadingIcon={<LicenseIcon />}
      onClick={() => {
        telemetry.trackEvent(telemetry.events.OPEN_LICENSE_MODAL);
        open('LicenseWarning');
      }}
    >
      <style>
        {`.license-button chameleon-linear-progress[aria-valuenow="100"]::part(determinate) {
          background-color: var(--goto-red-500);
        }
        .license-button {
          padding: 10px;
        }
        `}
      </style>
      { companyLicenses.avLicensesProvisioned > 0 &&
        <LinearProgress
          value={(100 * companyLicenses.avLicensesInstalled) / companyLicenses.avLicensesProvisioned}
        />
      }
      <Typography variant="caption-medium">
        {companyLicenses.avLicensesProvisioned === 0
          ? t('no-licenses-available')
          : t('licenses-used', {
              installed: companyLicenses.avLicensesInstalled,
              provisioned: companyLicenses.avLicensesProvisioned,
            })}
      </Typography>
    </Button>
  ) : null;
};

const ManagePoliciesButton = () => {
  const { t } = useTranslation('AntivirusPage');
  const { companyLicenses } = useLicenses();
  const isAdminUser =  hasCapability(ADMIN_CAPABILITY);
  const navigate = useNavigate();

  if (!companyLicenses || !companyLicenses.avLicensesProvisioned || !isAdminUser) {
    return null;
  }

  return (
    <Button
      size="large"
      variant="primary"
      leadingIcon={<SettingsOutlinedIcon />}
      onClick={() => navigate(Path.POLICIES, { replace: true })}
    >
      {t('manage-policies')}
    </Button>
  );
};

const HeaderControls = () => {
  const { options } = useContext(AppSettingContext);

  const parsedOptions: TAppOptions = options.startsWith('{') && JSON.parse(options);
  const avFeatureFlag = parsedOptions?.features?.AntivirusInstallMenu;
  const policiesFeatureFlag = parsedOptions?.features?.Policies;

  return <>
    {avFeatureFlag ? <LicenseInformation /> : null}
    {policiesFeatureFlag ? <ManagePoliciesButton /> : null}
  </>;
};

const AntivirusPage = () => {
  useEffect(() => {
    telemetry.trackEvent(telemetry.events.VIEW_ANTIVIRUS_PAGE, {
      integration_used: Integration.OPSWAT,
    });
  }, []);

  return (
    <>
      <BackgroundProcessBanner />
      <AntivirusTable />
    </>
  );
};

export default withLayout(AntivirusPage, { HeaderControls });
