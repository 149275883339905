import { useSyncExternalStore } from 'react';
import { BehaviorSubject } from 'rxjs';
import { TselectionSummaryData } from 'types';

export const selectionSummaryDataSubject$ = new BehaviorSubject<TselectionSummaryData | null>(null);

export const add = (data: TselectionSummaryData) => selectionSummaryDataSubject$.next(data);

export const clear = () => selectionSummaryDataSubject$.next(null);

const useSelectionSummaryData = () => {
  const selectionSummaryData = useSyncExternalStore(
    (listener: () => void) => {
      const subscription = selectionSummaryDataSubject$.subscribe(listener);

      return () => subscription.unsubscribe();
    },
    () => selectionSummaryDataSubject$.value
  );

  return {
    state: selectionSummaryData,
    add,
    clear,
  };
};

export default useSelectionSummaryData;
