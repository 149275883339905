import { HealthStatus } from 'app_constants/status';
import i18n from 'services/app/i18n';

export const getHealthStatus = (status: number) => {
  const t = i18n.getFixedT(null, 'DevicesTable');

  switch (status) {
    case HealthStatus.DEFINITION_OUTDATED: {
      return [t('update-definition')];
    }

    case HealthStatus.THREAT_FOUND: {
      return [t('threat-found')];
    }

    case HealthStatus.PROTECTED: {
      return [t('protected'), t('no-action-required')];
    }

    case HealthStatus.SCAN_IN_PROGRESS: {
      return [`${t('scan-in-progress')}`];
    }

    case HealthStatus.MULTIPLE_AVS_INSTALLED: {
      return [`${t('multiple-avs-installed')}`];
    }

    case HealthStatus.GTRE_EPP_INSTALLATION_IN_PROGRESS: {
      return [`${t('gtre-epp-installation-in-progress')}`];
    }

    case HealthStatus.UNSUPPORTED_DEVICE: {
      return [t('unknown'), t('unsupported-device')];
    }

    case HealthStatus.UNKNOWN:
    default: {
      return [t('unknown'), t('no-information-available')];
    }
  }
};
