import { LitElement, html, css } from 'lit';
import i18n, { TFunction } from 'services/app/i18n';
import { rtpMap, RtpState, TRtpState } from 'app_constants/status';

const COMPONENT_TAG_NAME = 'av-protection-status';

class ProtectionStatus extends LitElement {
  t: TFunction;
  status: TRtpState;

  static get properties() {
    return {
      status: { type: Number },
    };
  }

  static get styles() {
    return [
      css`
        .wrapper {
          display: flex;
          align-items: center;
        }

        .icon {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: var(--goto-spacing-02);
        }

        .icon--enabled {
          background: #00ff00;
        }

        .icon--disabled {
          background: #636363;
        }

        .icon--unknown {
          width: 6px;
          height: 6px;
          border: 2px solid #636363;
        }

        .text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.t = i18n.getFixedT(null, 'DevicesTable');
    this.status = RtpState.UNKNOWN;
  }

  render() {
    this.status = this.status || RtpState.UNKNOWN;
    const content = rtpMap[this.status].table;
    const className = rtpMap[this.status].class;

    return html`
      <div class="wrapper">
        <span class="icon icon--${className}"></span>
        <span title="${content}">${content}</span>
      </div>
    `;
  }
}

if (!customElements.get(COMPONENT_TAG_NAME)) {
  customElements.define(COMPONENT_TAG_NAME, ProtectionStatus);
}

export default ProtectionStatus;
