import 'synapse-js/ui-components/app-fragment';
import { useContext, useEffect, useRef } from "react";
import { AppSettingContext } from "../../shared/contexts/app_setting";
import { getSynapseToken } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { Path } from "../../app_constants/path";
import { CUSTOM_TAG_NAME } from "../../app_constants";

function PoliciesPage() {
  const { theme, language, navigationRoute } = useContext(AppSettingContext);
  const appNavigate = useNavigate();
  const token = getSynapseToken();
  const ref = useRef<HTMLElement>(null);


  useEffect(() => {
    const localRef = ref.current;
    const appFragmentEventListener = (e: Event) =>  {
      const customEvent = e as CustomEvent;

      const { type, prefix } = customEvent.detail;
      if (type === 'navigated' && prefix === '/antivirus') {
        appNavigate(Path.HOME);

        return;
      }

      const antivirusAppElement = document.getElementsByTagName(CUSTOM_TAG_NAME)[0];
      antivirusAppElement?.dispatchEvent(
        new CustomEvent('synapse-app-event', {
          detail: customEvent.detail,
          bubbles: true,
          composed: true
        })
      );
    };

    localRef?.addEventListener('synapse-app-fragment-event', appFragmentEventListener);

    return () => {
      localRef?.removeEventListener('synapse-app-fragment-event', appFragmentEventListener);
    };
  }, [ref, appNavigate]);

  return (
    <app-fragment
      app-name="policies"
      jwt-token={token}
      theme={theme}
      language={language}
      navigation-route={navigationRoute}
      ref={ref}
      style={{ height: '100vh' }}
    ></app-fragment>
  );
}

export default PoliciesPage;

