import { navigate } from 'synapse-js/events/navigation';
import { ExternalPath } from 'app_constants/path';
import { hasCapability } from 'services/user';

// eslint-disable-next-line react/display-name
const withAccessCheck = (AppComponent: React.ComponentType) => (): JSX.Element | null => {
  if (!hasCapability('anti-virus')) {
    navigate('', ExternalPath.DEVICES);

    return null;
  }

  return <AppComponent />;
};

export default withAccessCheck;
