import jwtDecode, { InvalidTokenError, JwtHeader } from 'jwt-decode';
import { TJwtDecodedData, TUserData } from 'types';

let _synapseToken: string | null = null;

export const getSynapseToken = (): string => {
  if (!_synapseToken) {
    throw new InvalidTokenError('Token is missing');
  }

  return _synapseToken;
};

export const setSynapseToken = (token: string): void => {
  try {
    jwtDecode<JwtHeader>(token, { header: true });
  } catch (error) {
    throw new InvalidTokenError('Token is invalid');
  }

  _synapseToken = token;
};

export const getUserFromToken = (): TUserData => {
  const token = getSynapseToken();
  const decodedData = jwtDecode<TJwtDecodedData>(token);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { exp, iat, iss, jti, nbf, ...userData } = decodedData;

  return userData;
};

const STANDARD_OR_PREMIUM_USER_CAPABILITY_MAPPING = 'anti-virus';
export const isStandardOrPremiumUser = (): boolean => {
  return getUserFromToken().capabilities.includes(STANDARD_OR_PREMIUM_USER_CAPABILITY_MAPPING);
};
