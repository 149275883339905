import environment from 'environments';

export const ensureStyles = (): void => {
  const link = document.createElement('link');
  link.href = `${environment.baseUrl}/styles.css`;
  link.type = 'text/css';
  link.rel = 'stylesheet';
  document.head.insertBefore(link, document.head.getElementsByTagName('base')[0]);
};

export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}