import { getUser } from 'services/user';
import { TPageView, TCustomProperties } from 'types';
import SegmentTelemetry from './Segment';

export * as events from 'app_constants/tracking';

const DEFAULT_ERROR_MESSAGE = 'Error: Unknown';

let _segmentTelemetry: SegmentTelemetry;
let _initialized = false;

export const initialize = (): void => {
  const user = getUser();

  if (!_initialized) {
    _segmentTelemetry = new SegmentTelemetry(user);

    _initialized = true;
  }
};

export const trackPageView = (): void => {
  const uri = document.location.pathname;
  const pageView: TPageView = { name: uri, uri };

  _segmentTelemetry.trackPageView(pageView);
};

export const trackEvent = (name: string, customProperties?: TCustomProperties): void => {
  _segmentTelemetry.trackEvent({ name }, customProperties);
};

export const logError = (exception: Error, extra?: Record<string, unknown>): void => {
  _segmentTelemetry.logError(exception, extra);
};

export const logErrorWithDescription = (description: string) => (error?: unknown) => {
  const wrappedError =
    error instanceof Error
      ? error
      : typeof error === 'string'
      ? new Error(error)
      : new Error(description ?? DEFAULT_ERROR_MESSAGE);

  logError(wrappedError, { description });
};
