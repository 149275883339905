import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IconButton } from '@getgo/chameleon-web-react-wrapper';
import { VisibilityOnFilledIcon, CloseOutlinedIcon } from '@getgo/chameleon-icons/react';
import { BannerStates } from 'shared/contexts/commands';
import useBackgroundProcessBanner from 'observables/banner';
import './BackgroundProcessBanner.scss';

const BackgroundProcessControls = () => {
  const { t } = useTranslation('BackgroundProcessBanner');
  const { state, setClosed } = useBackgroundProcessBanner();
  const { bannerState } = state;

  const viewScanResults = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log('viewScanResults');
  }, []);

  const completedControls = useMemo(
    () => (
      <>
        <Button leadingIcon={<VisibilityOnFilledIcon />} onClick={viewScanResults}>
          {t('view-scan-results-button')}
        </Button>
        <IconButton variant="secondary" onClick={setClosed}>
          <CloseOutlinedIcon />
        </IconButton>
      </>
    ),
    [t, viewScanResults, setClosed]
  );

  const runningControls = useMemo(
    () => null,
    // () => (
    //   <>
    //     <Button variant="neutral" onClick={viewScanProgress}>
    //       {t('view-scan-progress-button')}
    //     </Button>
    //     <Button leadingIcon={<VisibilityEnabledInactiveIcon />}>
    //       {t('view-scan-results-button')}
    //     </Button>
    //   </>
    // ),
    []
  );

  const controls = useMemo(
    () => ({
      [BannerStates.COMPLETED]: completedControls,
      [BannerStates.RUNNING]: runningControls,
    }),
    [completedControls, runningControls]
  );

  if (bannerState === BannerStates.CLOSED) return null;

  return <div className="process-banner__controls">{controls[bannerState]}</div>;
};

export default BackgroundProcessControls;
