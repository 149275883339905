import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast, resetToasts } from 'synapse-js/events/toast';

const useToastNotification = () => {
  const { t } = useTranslation('Common');

  const DEFAULT_ERROR_MESSAGE = useMemo(() => t('Error:500'), [t]);

  const showSuccess = useCallback((message: string) => {
    addToast(message, 'success', '');
  }, []);

  const showWarning = useCallback((message: string) => {
    addToast(message, 'info', '');
  }, []);

  const showError = useCallback(
    (message?: string) => {
      addToast(message || DEFAULT_ERROR_MESSAGE, 'error', '');
    },
    [DEFAULT_ERROR_MESSAGE]
  );

  const reset = useCallback(() => {
    resetToasts();
  }, []);

  return {
    showSuccess,
    showWarning,
    showError,
    reset,
  };
};

export default useToastNotification;
