import { useLayoutEffect, useState, useRef } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { companyApi } from 'services/api';
import { Path } from 'app_constants/path';
import telemetry from 'services/app/telemetry';
import { logErrorWithDescription } from 'services/app/telemetry/telemetry';
import { TDeviceFilter } from 'components/AntivirusTable/types';

export type NavigateToDevicesListPage = (
  path: typeof Path.DEVICES_LIST,
  params: {
    replace?: boolean;
    state: {
      devicesFilter: DevicesFilterRecord;
    };
  }
) => void;

const useSettings = () => {
  const navigate = useNavigate();
  const devicesListRouterNavigate = navigate as NavigateToDevicesListPage;

  const location = useLocation();
  const searchParam = new URLSearchParams(location.search).get('devicesFilter');
  const validatedDevicesFilter = getValidatedDevicesFilter(searchParam);
  const devicesFilter = useRef<DevicesFilterRecord>(validatedDevicesFilter);

  const [enabledProtectionClicked, setEnabledProtectionClicked] = useState(false);

  const { data: userSettings, isLoading: isGetStarted } = useQuery(
    ['user-settings'],
    companyApi.getStarted,
    {
      onError: logErrorWithDescription('Error: fetching getStarted status')
    }
  );

  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  const { mutateAsync } = useMutation(companyApi.setGetStarted, {
    onSuccess: async () => {
      await timeout(1000);
      devicesListRouterNavigate(
        Path.DEVICES_LIST, {
        replace: true,
        state: { devicesFilter: devicesFilter.current }
      });
    },
    onError: (error: unknown) => {
      logErrorWithDescription('Error: enabling protection')(error);
      navigate(Path.ENABLE_PROTECTION, { replace: true });
    },
  });

  const enableProtection = async () => {
    telemetry.trackEvent(telemetry.events.ENABLE_ENDPOINT_PROTECTION);

    setEnabledProtectionClicked(true);

    navigate(Path.GETSTARTED, { replace: true });
    await mutateAsync();
  };

  useLayoutEffect(() => {
    if (userSettings && !enabledProtectionClicked && !isGetStarted) {
      let pathToRedirect: Path = location.pathname as Path;

      if (userSettings.getStarted) {
        pathToRedirect = Path.ENABLE_PROTECTION;
      }

      pathToRedirect === Path.DEVICES_LIST ?
        navigate(pathToRedirect, { replace: true, state: { devicesFilter: devicesFilter.current } }) :
        navigate(pathToRedirect, { replace: true });
    }
  }, [enabledProtectionClicked, location.pathname, navigate, userSettings, isGetStarted]);

  return {
    isGetStarted,
    enableProtection,
  };
};

const DEVICES_FILTER: Record<number, TDeviceFilter['trackingProp']> = {
  0: 'all_devices_list',
  1: 'GTRe_EPP_devices_list',
  2: 'pre_installed_AV_devices_list',
} as const;

export type DevicesFilterRecord = { idx: number, filter: TDeviceFilter['trackingProp'] };
export function getValidatedDevicesFilter(urlSearchParam?: string | TDeviceFilter['trackingProp'] | null): DevicesFilterRecord {
  type T = TDeviceFilter['trackingProp'];

  const idx = Object.values(DEVICES_FILTER).indexOf(urlSearchParam as T);
  const filter = idx !== -1 ? urlSearchParam as T : 'all_devices_list';

  return { idx: idx !== -1 ? idx : 0, filter };
}

export default useSettings;
