import { useMemo } from 'react';
import { usePoliciesByType } from './usePoliciesByType';
import { AVPolicyInfo, PolicyType } from 'types';

export function useDevicePolicyAssignments(type: PolicyType): Map<string, AVPolicyInfo> | undefined {
  const { policies } = usePoliciesByType(type);

  return useMemo(() => {
    if (!policies) {
      return;
    }

    return createDeviceToPolicyMap(policies);
  }, [policies]);
}

export const createDeviceToPolicyMap = (policyInfos: AVPolicyInfo[]): Map<string, AVPolicyInfo> => {
  // the key of the map is the device Id
  const dictionary = new Map<string, AVPolicyInfo>();
  policyInfos.forEach(policy => {
    policy.devices.forEach(deviceId => {
      dictionary.set(deviceId, policy);
    });
  });

  return dictionary;
};
