import { useSyncExternalStore } from 'react';
import { BehaviorSubject } from 'rxjs';
import { selectedDevicesSubject$ } from 'observables/selectedDevices';

export const selectedGroupsSubject$ = new BehaviorSubject<(string | null)[]>([]);

export const add = (groups: (string | null)[]) => {
  const previousGroups = selectedGroupsSubject$.value;
  const updatedGroups = [...groups];
  const removedGroups = previousGroups.filter((groupId) => !updatedGroups.includes(groupId));

  if (removedGroups.length) {
    selectedDevicesSubject$.next(
      selectedDevicesSubject$.value.filter((device) => {
        const deviceGroupId = device.deviceGroupId
          ? device.deviceGroupId.replace(/^group-/, '')
          : null;

        return !removedGroups.includes(deviceGroupId);
      })
    );
  }

  selectedGroupsSubject$.next(updatedGroups);
};

export const clear = () => selectedGroupsSubject$.next([]);

const useSelectedGroups = () => {
  const groups = useSyncExternalStore(
    (listener: () => void) => {
      const subscription = selectedGroupsSubject$.subscribe(listener);

      return () => subscription.unsubscribe();
    },
    () => selectedGroupsSubject$.value
  );

  return {
    state: groups,
    add,
    clear,
  };
};

export default useSelectedGroups;
