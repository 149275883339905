export const CommandTypes = {
  TERMINATE_APP: 0,
  DISCOVERY: 1,
  FULL_SCAN: 2,
  UPDATE_DEFINITIONS: 3,
  ENABLE_RTP: 4,
  DEFAULT_SCAN: 5,
  PATH_SCAN: 6,
  ENABLE_FIREWALL: 7,
  DISABLE_FIREWALL: 8,
} as const;
