import { ApiEndpoint } from 'app_constants/path';
import http from 'services/app/http';
import { QueryFunction } from '@tanstack/react-query';

export type TQueryKey = readonly [string, { id: string }];

type TThreat = {
  remediation: 'quarantined' | 'deleted' | 'cleaned' | 'partial' | 'none';
  path: string;
  scanTime: string;
  threatName: string;
};

type TScanResult = {
  data: {
    scanResult: {
      threats: TThreat[] | null;
      createdOn: string | null;
      startedOn: string | null;
      endedOn: string | null;
    };
  };
};

export const getLastScan: QueryFunction<TScanResult, TQueryKey> = async ({ queryKey }) => {
  const url = `${ApiEndpoint.REPORTS}/device/antivirus-status?deviceId=${queryKey[1].id}`;

  return http.get(url);
};
