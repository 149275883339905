import React from 'react';
import { Root, createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@getgo/chameleon-web-react-wrapper';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppSettingProvider from 'shared/contexts/app_setting';
import { App } from 'components';
import {
  CUSTOM_TAG_NAME,
  OPTIONS_ATTRIBUTE,
  THEME_ATTRIBUTE,
  JWT_TOKEN_ATTRIBUTE,
  OBSERVABLE_ATTRIBUTES,
  SKIN_GOTO_RESOLVE_ATTRIBUTE,
  LANGUAGE_ATTRIBUTE,
  ENVIRONMENTS,
  NAVIGATION_ROUTE_ATTRIBUTE,
} from 'app_constants';
import { THEME, SYNAPSE_THEME, JWT_TOKEN, OPTIONS, LANGUAGE, NAVIGATION_ROUTE } from './CustomElement.d';
import environment from 'environments';
import { getUserFromToken, setSynapseToken } from 'services/auth';
import { setUser } from 'services/user';
import i18n from 'services/app/i18n';
import RunScanProvider from 'shared/contexts/commands';
import telemetry from 'services/app/telemetry';

const rqClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export interface AppProps {
  [THEME_ATTRIBUTE]?: SYNAPSE_THEME;
  [LANGUAGE_ATTRIBUTE]?: LANGUAGE;
  [JWT_TOKEN_ATTRIBUTE]?: JWT_TOKEN;
  [OPTIONS_ATTRIBUTE]?: OPTIONS;
  [NAVIGATION_ROUTE_ATTRIBUTE]?: NAVIGATION_ROUTE;
}

class CustomElement extends HTMLElement {
  root?: Root;
  state: AppProps;

  constructor() {
    super();
    this.state = {};
  }

  render() {
    this.root?.render(
      <React.StrictMode>
        <ThemeProvider
          theme={this.state[THEME_ATTRIBUTE]?.split('-').pop() as THEME}
          rebranding2021
          skin={SKIN_GOTO_RESOLVE_ATTRIBUTE}
        >
          <AppSettingProvider settings={this.state}>
            <QueryClientProvider client={rqClient}>
              <BrowserRouter basename={environment.routerBasename} window={window.parent}>
                <RunScanProvider>
                  <App />
                  {environment.env === ENVIRONMENTS.DEV ? (
                    <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
                  ) : null}
                </RunScanProvider>
              </BrowserRouter>
            </QueryClientProvider>
          </AppSettingProvider>
        </ThemeProvider>
      </React.StrictMode>
    );
  }

  connectedCallback() {
    const rootElement = document.getElementsByTagName(CUSTOM_TAG_NAME)[0];
    this.root = createRoot(rootElement);
    this.render();
  }

  disconnectedCallback() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.root!.unmount();
  }

  static get observedAttributes() {
    return OBSERVABLE_ATTRIBUTES;
  }

  attributeChangedCallback(
    attributeName: keyof typeof this.state,
    previousAttributeValue: string | undefined,
    attributeValue: string
  ) {
    this.state[attributeName] = attributeValue;

    if (
      attributeName === 'language' &&
      attributeValue &&
      previousAttributeValue !== attributeValue
    ) {
      i18n.changeLanguage(attributeValue);
      localStorage.setItem('language', attributeValue);
    }

    if (
      attributeName === JWT_TOKEN_ATTRIBUTE &&
      attributeValue &&
      previousAttributeValue !== attributeValue
    ) {
      setSynapseToken(attributeValue);
      const user = getUserFromToken();
      setUser(user);

      telemetry.initialize();
    }
    this.render();
  }
}

if (!customElements.get(CUSTOM_TAG_NAME)) {
  customElements.define(CUSTOM_TAG_NAME, CustomElement);
}

export default CustomElement;
