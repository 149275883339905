import i18n from 'services/app/i18n';

const t = i18n.getFixedT(null, 'DevicesTable');

export const HealthStatus = {
  UNKNOWN: 0,
  PROTECTED: 1,
  THREAT_FOUND: 2,
  DEFINITION_OUTDATED: 3,
  SCAN_IN_PROGRESS: 4,
  MULTIPLE_AVS_INSTALLED: 5,
  GTRE_EPP_INSTALLATION_IN_PROGRESS: 6,
  UNSUPPORTED_DEVICE: 7,
} as const;

export type THealthStatus = (typeof HealthStatus)[keyof typeof HealthStatus];

export const ScanStatus = {
  UNKNOWN: 0,
  IN_PROGRESS: 1,
  READY_TO_SCAN: 2,
} as const;

export type TScanStatus = (typeof ScanStatus)[keyof typeof ScanStatus];

export const RtpStatus = {
  UNKNOWN: 0,
  ENABLED: 1,
  DISABLED: 2,
  UNSUPPORTED_DEVICE: 3,
} as const;

export const RtpState = {
  UNKNOWN: 'UNKNOWN',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  UNSUPPORTED_DEVICE: 'UNSUPPORTED_DEVICE',
} as const;

export const rtpMap = {
  [RtpState.UNKNOWN]: {
    table: t('unknown'),
    class: 'unknown',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [RtpState.UNSUPPORTED_DEVICE]: {
    table: t('unsupported-device'),
    class: 'unsupported-device',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [RtpState.ENABLED]: {
    table: t('enabled'),
    class: 'enabled',
    menu: t('Common:disable') + ' ' + t('option-rtp'),
    report: t('real-time-protection') + ' ' + t('enabled').toLowerCase(),
  },
  [RtpState.DISABLED]: {
    table: t('disabled'),
    class: 'disabled',
    menu: t('Common:enable') + ' ' + t('option-rtp'),
    report: t('real-time-protection') + ' ' + t('disabled').toLowerCase(),
  },
};

export const RtpStatusToState = new Map([
  [RtpStatus.UNKNOWN, RtpState.UNKNOWN],
  [RtpStatus.ENABLED, RtpState.ENABLED],
  [RtpStatus.DISABLED, RtpState.DISABLED],
  [RtpStatus.UNSUPPORTED_DEVICE, RtpState.UNSUPPORTED_DEVICE],
  [undefined, RtpState.UNKNOWN],
  [null, RtpState.UNKNOWN],
]);

export type TRtpState = (typeof RtpState)[keyof typeof RtpState];
export type TRtpStatus = (typeof RtpStatus)[keyof typeof RtpStatus];

export const FirewallStatus = {
  UNSUPPORTED_DEVICE: -2,
  NOTSUPPORTED: -1,
  UNKNOWN: 0,
  ENABLED: 1,
  DISABLED: 2,
} as const;

export const FirewallState = {
  UNSUPPORTEDDEVICE: 'UNSUPPORTEDDEVICE',
  NOTSUPPORTED: 'NOTSUPPORTED',
  UNKNOWN: 'UNKNOWN',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
} as const;

export const FirewallStatusToState = new Map([
  [FirewallStatus.UNSUPPORTED_DEVICE, FirewallState.UNSUPPORTEDDEVICE],
  [FirewallStatus.NOTSUPPORTED, FirewallState.NOTSUPPORTED],
  [FirewallStatus.UNKNOWN, FirewallState.UNKNOWN],
  [FirewallStatus.ENABLED, FirewallState.ENABLED],
  [FirewallStatus.DISABLED, FirewallState.DISABLED],
]);

export const firewallMap = {
  [FirewallState.UNSUPPORTEDDEVICE]: {
    table: t('unsupported-device'),
    class: 'unsupported-device',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [FirewallState.NOTSUPPORTED]: {
    table: t('notsupported'),
    class: 'notsupported',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [FirewallState.UNKNOWN]: {
    table: t('unknown'),
    class: 'unknown',
    menu: t('unknown'),
    report: t('unknown'),
  },
  [FirewallState.ENABLED]: {
    table: t('enabled'),
    class: 'enabled',
    menu: t('Common:disable') + ' ' + t('option-firewall'),
    report: t('firewall') + ' ' + t('enabled').toLowerCase(),
  },
  [FirewallState.DISABLED]: {
    table: t('disabled'),
    class: 'disabled',
    menu: t('Common:enable') + ' ' + t('option-firewall'),
    report: t('firewall') + ' ' + t('disabled').toLowerCase(),
  },
};

export type TFirewallState = (typeof FirewallState)[keyof typeof FirewallState];
export type TFirewallStatus = (typeof FirewallStatus)[keyof typeof FirewallStatus];

export const DeviceStatus = {
  ONLINE: 'Online',
  IN_SESSION: 'In Session',
  OFFLINE: 'Offline',
} as const;

export type TDeviceStatus = (typeof DeviceStatus)[keyof typeof DeviceStatus];

export const HelpdeskPriorityStatus = {
  URGENT: 1,
  HIGH: 2,
  MEDIUM: 3,
  LOW: 4,
} as const;

export type THelpdeskPriorityStatus =
  (typeof HelpdeskPriorityStatus)[keyof typeof HelpdeskPriorityStatus];

export const AppInstallationState = {
  AVAILABLE: 'AVAILABLE',
  UNKNOWN_ERROR: 'UNKNOWNERROR',
  WINGET_NOT_FOUND: 'WINGETNOTFOUND',
  WINGET_NOT_ACCESSIBLE: 'WINGETNOTACCESSIBLE',
} as const;

export type TAppInstallationState =
  (typeof AppInstallationState)[keyof typeof AppInstallationState];
