import { TDevice } from 'types';

export enum Actions {
  SET_NAME = 'SET_NAME',
  SET_OPENED = 'SET_OPENED',
  SET_CLOSED = 'SET_CLOSED',
  SET_OPENED_FROM_BANNER = 'SET_OPENED_FROM_BANNER',
  SET_SUBMIT_DISABLED = 'SET_SUBMIT_DISABLED',
  SET_SUBMIT_ENABLED = 'SET_SUBMIT_ENABLED',
  SET_REQUEST_ENABLED = 'SET_REQUEST_ENABLED',
  SET_REQUEST_DISABLED = 'SET_REQUEST_DISABLED',
}

export type State = {
  name: string;
  opened: boolean;
  fromBanner: boolean;
  isDisabledSubmit: boolean;
  isEnabledRequest: boolean;
  devices: TDevice[];
};

export type Action = {
  type: string;
  payload?: unknown | string;
};

export const initialState: State = {
  name: 'QuickScanModal',
  opened: false,
  fromBanner: false,
  isDisabledSubmit: false,
  isEnabledRequest: false,
  devices: [],
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case Actions.SET_NAME:
      return { ...state, name: action.payload as string };
    case Actions.SET_OPENED:
      return { ...state, opened: true, fromBanner: false, devices: action.payload as TDevice[] };
    case Actions.SET_CLOSED:
      return initialState;
    case Actions.SET_OPENED_FROM_BANNER:
      return { ...state, opened: true, fromBanner: true, devices: action.payload as TDevice[] };
    case Actions.SET_SUBMIT_ENABLED:
      return { ...state, isDisabledSubmit: false };
    case Actions.SET_SUBMIT_DISABLED:
      return { ...state, isDisabledSubmit: true };
    case Actions.SET_REQUEST_ENABLED:
      return { ...state, isEnabledRequest: true, isDisabledSubmit: true };
    case Actions.SET_REQUEST_DISABLED:
      return { ...state, isEnabledRequest: false, isDisabledSubmit: false };
    default:
      return state;
  }
};

export default reducer;
