import React from 'react';
import Header, { HeaderProps } from 'components/Header';

export function withLayout<PageProps extends Record<string, unknown>>(
  PageComponent: React.ComponentType<PageProps>,
  headerProps?: HeaderProps
) {
  const displayName = PageComponent.displayName || PageComponent.name || 'Component';

  const ComponentWithLayout = (props: PageProps) => (
    <div className="container">
      <Header {...headerProps} />
      <PageComponent {...props} />
    </div>
  );

  ComponentWithLayout.displayName = `withLayout(${displayName})`;

  return ComponentWithLayout;
}
