import http from 'services/app/http';
import { ApiEndpoint } from 'app_constants/path';

export type GetStartedSettings = {
  getStarted?: boolean;
};

export const getStarted = async () => {
  return await http.get<GetStartedSettings>(`${ApiEndpoint.COMPANY}/get-started`);
};

export type CompanyLicenses = {
  avLicensesInstalled: number;
  avLicensesProvisioned: number;
};

export const getCompanyLicenses = async () => {
  return await http.get<CompanyLicenses>(`${ApiEndpoint.COMPANY}/licenses`);
};

export const setGetStarted = async () => {
  await http.post(`${ApiEndpoint.COMPANY}/get-started`);
};
