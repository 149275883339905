import { Route, Routes, Navigate } from 'react-router-dom';
import { AntivirusPage, EnableProtectionPage, DiscoveryPage, PoliciesPage } from 'pages';
import { Loader } from 'shared/components';
import { Path } from 'app_constants/path';
import { useSettings } from 'shared/hooks';
import { withAccessCheck } from 'shared/HOCs';

function App() {
  const { isGetStarted, enableProtection } = useSettings();

  // TODO enable when backend implementation is done
  // useRTConnection();

  if (isGetStarted) {
    return <Loader />;
  }

  return (
    <Routes>
      <Route path={Path.HOME} element={<Navigate to={Path.DEVICES_LIST} replace={true} />} />
      <Route
        path={Path.ENABLE_PROTECTION}
        element={<EnableProtectionPage enableProtection={enableProtection} />}
      />
      <Route path={Path.GETSTARTED} element={<DiscoveryPage />} />
      <Route path={Path.DEVICES_LIST} element={<AntivirusPage />} />
      <Route path={Path.POLICIES} element={<PoliciesPage />} />
      <Route path={Path.POLICY_DETAILS} element={<PoliciesPage />} />
      <Route path={Path.POLICIES_ALL} element={<PoliciesPage />} />
      <Route path="*" element={<Navigate to={Path.DEVICES_LIST} replace={true} />} />
    </Routes>
  );
}

export default withAccessCheck(App);
