import { createElement, useEffect } from 'react';
import { Button, LinearProgress, Typography } from '@getgo/chameleon-web-react-wrapper';
import { ExternalLinkOutlinedIcon } from '@getgo/chameleon-icons/react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'shared/hooks';
import { useInvalidateLicenses, useLicenses } from 'shared/hooks/useLicenses/useLicenses';
import { ExternalPath } from 'app_constants/path';
import telemetry from 'services/app/telemetry';
import './LicenseWarning.scss';
import environments from 'environments';
import { getUser } from 'services/user';

export const LicenseWarning = () => {
  const { t } = useTranslation('LicenseWarning');
  const { companyLicenses } = useLicenses();
  const invalidateLicenses = useInvalidateLicenses();

  useEffect(() => () => telemetry.trackEvent(telemetry.events.CLOSE_LICENSE_MODAL));
  useEffect(() => {
    invalidateLicenses();
  }, [invalidateLicenses]);

  return (
    <div className="license-wraning-content">
      {companyLicenses && (
        <div className="license-warning-content__license-counter">
          <LicenseIcon size={40} />
          <div>
            <LinearProgress
              {...{ size: 'large' }}
              value={
                ((100 * companyLicenses.avLicensesInstalled) /
                  (companyLicenses.avLicensesProvisioned || 1)) |
                0
              }
            />
            <Typography variant="body-small">
              {companyLicenses.avLicensesProvisioned === 0
                ? t('no-licenses-available')
                : t('licenses-used', {
                    installed: companyLicenses.avLicensesInstalled,
                    provisioned: companyLicenses.avLicensesProvisioned,
                  })}
            </Typography>
          </div>
        </div>
      )}
      <Typography variant="body-medium-strong">{t('need-more')}</Typography>
      <Typography variant="body-medium">{t('purchase-licenses')}</Typography>
      <Typography variant="body-medium">{t('removed-licenses')}</Typography>
    </div>
  );
};

const openWindow = (url: string) => window.open(url, '_blank') || location.assign(url);

export const Actions = () => {
  const { t } = useTranslation('LicenseWarning');
  const { close } = useModal();

  const companyId = getUser().company.id;
  const gotoAdminUrl = environments.gotoAdminMicroTransactionUrl.replace('{COMPANY_ID}', companyId);

  return (
    <>
      <Button
        onClick={() => {
          telemetry.trackEvent(telemetry.events.MANAGE_LICENSES);
          openWindow(gotoAdminUrl);
          close();
        }}
        leadingIcon={<ExternalLinkOutlinedIcon />}
      >
        {t('manage-licenses')}
      </Button>
      <Button
        onClick={() => {
          telemetry.trackEvent(telemetry.events.CONTACT_SALES);
          openWindow(ExternalPath.SALES);
          close();
        }}
        variant="tertiary"
        leadingIcon={<ExternalLinkOutlinedIcon />}
      >
        {t('contact-sales')}
      </Button>
    </>
  );
};

export const LicenseIcon = (props: { size?: number }) => {
  return createElement(
    'chameleon-svg',
    {
      width: props.size || 24,
      height: props.size || 24,
      slot: 'start',
      style: {
        '--goto-icon-size': `${props.size || 24}px`,
      },
    },
    <div className="resolved-icon">
      <svg
        role="img"
        width={props.size || 24}
        height={props.size || 24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#corner)">
          <path d="M21 2.99951H14V1.99951H22V9.99951H21V2.99951Z" fill="#8552F6" />
        </g>
        <path
          d="M13 1.99951H2V11.0002C2.00238 12.6087 2.42642 14.1885 3.22985 15.582C4.03327 16.9754 5.18796 18.134 6.57882 18.9419L12 21.9723L17.414 18.946L17.4211 18.9419C18.812 18.1339 19.9667 16.9754 20.7702 15.582C21.5736 14.1885 21.9976 12.6087 22 11.0002L21 10.9988C20.9979 12.4324 20.6199 13.8405 19.9038 15.0825C19.1884 16.3233 18.1605 17.3551 16.9224 18.0751L12 20.8267L7.0776 18.0751C5.83951 17.3551 4.81158 16.3233 4.09617 15.0825C3.38013 13.8406 3.00218 12.4327 3 10.9991V2.99951H13V1.99951Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 3.99951L13 9.49951L16 10.4995L9.5 17.9995L11 12.4995L8 11.4995L14.5 3.99951ZM11.7863 10.149L14.2645 10.9751L11.6201 14.0263L12.2137 11.85L9.73548 11.0239L12.3799 7.97268L11.7863 10.149Z"
          fill="currentColor"
        />
        <defs>
          <clipPath id="corner">
            <rect width="8" height="8" fill="white" transform="translate(14 1.99951)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
LicenseIcon.displayName = 'LicenseIcon';

export default LicenseWarning;
