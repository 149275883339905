import { useTranslation } from 'react-i18next';
import { ProgressIndicator, Typography } from '@getgo/chameleon-web-react-wrapper';
import { withLayout } from 'shared/HOCs';
import DeviceIcon from './device.svg';
import './DiscoveryPage.scss';

const DiscoveryPage = () => {
  const { t } = useTranslation('Discovery');

  return (
    <div className="discovery">
      <DeviceIcon />
      <Typography tag="h2" variant="heading-medium">
        {t('headline')}
      </Typography>
      <ProgressIndicator value={null} className="discovery__progress" />
      <div className="discovery__description">
        <Typography
          variant="body-small"
          color="type-color-secondary"
          className="discovery__paragraph"
        >
          {t('description-1-paragraph')}
        </Typography>
      </div>
    </div>
  );
};

export default withLayout(DiscoveryPage);
