import { LitElement, html, css, TemplateResult } from 'lit';
import i18n, { TFunction } from 'services/app/i18n';
import {
  SVG_SCREEN_OUTLINED,
  SVG_CLOSE_OUTLINED,
  SVG_SEARCH_OUTLINED,
  SVG_FOLDER_OUTLINED,
  SVG_DOWNLOAD_FILLED,
  SVG_ENCRYPTION_OUTLINED,
  SVG_PRESENCE_BUSY_INACTIVE,
} from '@getgo/chameleon-icons';
import { TselectionSummaryData } from 'types';
import { CommandTypes } from 'app_constants/command-types';
import { HealthStatus } from 'app_constants/status';

const COMPONENT_TAG_NAME = 'av-devices-table-drawer';

class Drawer extends LitElement {
  t: TFunction;
  isDrawerOpen: boolean;
  isSelectionSummaryLoading: boolean;
  selectionSummaryData: TselectionSummaryData | null;

  static get properties() {
    return {
      isDrawerOpen: {
        type: String,
        attribute: 'is-drawer-open',
        converter: (value: string | null) => {
          return value ? JSON.parse(value) : false;
        },
      },
      isSelectionSummaryLoading: {
        type: String,
        attribute: 'is-selection-summary-loading',
        converter: (value: string | null) => {
          return value ? JSON.parse(value) : false;
        },
      },
      selectionSummaryData: {
        type: String,
        attribute: 'selection-summary-data',
        converter: (value: string | null) => {
          return value ? JSON.parse(value) : null;
        },
      },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          --drawer-bg-color: var(--goto-surface-color-default);
          --drawer-border-color: var(--goto-border-color-default);
        }

        .drawer-close-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-top: 0;
          --goto-icon-size: 18px;
          --goto-icon-color: var(--goto-icon-color-default);
          border-radius: 50%;
        }

        .drawer-close-button:hover {
          background-color: var(--goto-interactive-color-secondary-hover);
        }

        .drawer-body-wrapper {
          position: absolute;
          inset: 89px 16px 69px;
          padding-bottom: 26px;
          overflow-y: auto;
        }

        .drawer-summary {
          display: flex;
          flex-direction: column;
          padding: 21px;
          gap: 24px;
          margin-bottom: 24px;
          background: var(--goto-interactive-color-secondary-hover);
          border: 1px solid var(--goto-border-color-default);
          border-radius: 4px;
        }

        .drawer-summary-section-header {
          padding-bottom: 5px;
        }

        .drawer-summary-section-header__icon {
          float: left;
          --goto-icon-size: 24px;
          margin-right: 6px;
        }

        .drawer-summary-text-skeleton {
          display: block;
          position: relative;
          top: 4px;
          margin-bottom: 6px;
        }

        .drawer-buttons-section {
          padding-bottom: 25px;
        }

        .drawer-buttons-section__title {
          margin-bottom: 8px;
        }

        .drawer-buttons-section__button + .drawer-buttons-section__button {
          margin-top: 8px;
        }

        .clear-button-wrapper {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 16px;
          border-top: 1px solid var(--goto-border-color-default);
          background: var(--goto-surface-color-default);
        }

        .enable-buttons-block {
          display: flex;
          gap: 3px 8px;
          flex-wrap: wrap;
          padding-bottom: 10px;
        }

        .enable-buttons-block__title {
          width: 100%;
        }

        .enable-buttons-block > .enable-buttons-block__button {
          flex: 1;
        }

        .drawer-header {
          position: absolute;
          top: 32px;
          padding-left: 1em;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.isDrawerOpen = false;
    this.isSelectionSummaryLoading = false;
    this.selectionSummaryData = null;
    this.t = i18n.getFixedT(null, ['DevicesTable', 'Common']);
  }

  renderWithLoader(isLoading: boolean, loaderWidth: number, content: TemplateResult) {
    return isLoading
      ? html`<chameleon-skeleton
          class="drawer-summary-text-skeleton"
          width=${loaderWidth}
          variant="rectangle"
          size="xxsmall"
        ></chameleon-skeleton>`
      : content;
  }

  render() {
    const isDataLoading = !this.selectionSummaryData || this.isSelectionSummaryLoading;

    return html`
      <synapse-drawer .open=${this.isDrawerOpen}>
        <div class="drawer-close-button" slot="close" @click=${this.closeDrawer}>
          <chameleon-svg slot="start">${SVG_CLOSE_OUTLINED}</chameleon-svg>
        </div>
        <div class="drawer-header">
          <chameleon-typography variant="avatar-large">
            ${this.renderWithLoader(
              isDataLoading,
              230,
              html`${this.t('device-selected', {
                count: this.selectionSummaryData?.devicesSelected,
              })}`
            )}
          </chameleon-typography>
        </div>
        <div class="drawer-body-wrapper">
          <div class="drawer-summary">
            ${html`
              <div>
                <chameleon-typography
                      class="drawer-summary-section-header"
                      variant="body-medium"
                    >
                  <chameleon-svg class="drawer-summary-section-header__icon"
                    >${SVG_ENCRYPTION_OUTLINED}</chameleon-svg
                  >
                  ${this.t('antivirus-health-summary')}
                </chameleon-typography>
                ${this.renderWithLoader(
                  isDataLoading,
                  100,
                  html`<chameleon-typography variant="body-small">
                    ${this.t('threat-found', {
                      count:
                        this.selectionSummaryData?.avHealthData[HealthStatus.THREAT_FOUND] || 0,
                    })}
                  </chameleon-typography>`
                )}
                ${this.renderWithLoader(
                  isDataLoading,
                  170,
                  html`<chameleon-typography variant="body-small">
                    ${this.t('virus-definition-update', {
                      count:
                        this.selectionSummaryData?.avHealthData[
                              HealthStatus.DEFINITION_OUTDATED
                            ] || 0,
                    })}
                  </chameleon-typography>`
                )}
                ${this.renderWithLoader(
                  isDataLoading,
                  215,
                  html`<chameleon-typography variant="body-small">
                    ${this.selectionSummaryData?.avHealthData[HealthStatus.PROTECTED] || 0}
                    ${this.t('protected')} - ${this.t('no-action-required')}
                  </chameleon-typography>`
                )}
              </div>
            `}
            <div>
              <chameleon-typography class="drawer-summary-section-header" variant="body-medium">
                <chameleon-svg class="drawer-summary-section-header__icon"
                  >${SVG_SCREEN_OUTLINED}</chameleon-svg
                >
                ${this.t('devices')}
              </chameleon-typography>
              ${this.renderWithLoader(
                isDataLoading,
                125,
                html`<chameleon-typography variant="body-small-strong" color="type-color-secondary">
                  ${this.t('device-selected', {
                    count: this.selectionSummaryData?.devicesSelected,
                  })}
                </chameleon-typography>`
              )}
            </div>
            <div>
              <chameleon-typography class="drawer-summary-section-header" variant="body-medium">
                <chameleon-svg class="drawer-summary-section-header__icon"
                  >${SVG_FOLDER_OUTLINED}</chameleon-svg
                >
                ${this.t('device-groups-in-selection')}
              </chameleon-typography>
              ${this.renderWithLoader(
                isDataLoading,
                205,
                html`<chameleon-typography variant="body-small-strong" color="type-color-secondary"
                  >${this.t('from-device-group', {
                    count: this.selectionSummaryData?.groupsSelected,
                  })}</chameleon-typography
                >`
              )}
            </div>
          </div>
          ${html`
          <div class="drawer-buttons-section">
            <chameleon-typography
                    class="drawer-buttons-section__title"
                    variant="body-small-strong"
              >${this.t('scans')}</chameleon-typography
            >
            <chameleon-button
              class="drawer-buttons-section__button"
              .disabled=${isDataLoading ||
              !this.selectionSummaryData?.validationData[CommandTypes.DEFAULT_SCAN]}
              @click=${this.onSelectedQuickScanClicked}
              fullwidth
            >
              <chameleon-svg slot="start">${SVG_SEARCH_OUTLINED}</chameleon-svg>
              ${this.t('run-quick-scan')}
            </chameleon-button>
            <chameleon-button
              class="drawer-buttons-section__button"
              .disabled=${isDataLoading ||
              !this.selectionSummaryData?.validationData[CommandTypes.FULL_SCAN]}
              @click=${this.onSelectedFullScanClicked}
              variant="neutral"
              fullwidth
            >
              <chameleon-svg slot="start">${SVG_FOLDER_OUTLINED}</chameleon-svg>
              ${this.t('run-full-scan')}
            </chameleon-button>
            <chameleon-typography variant="caption-small"
              >${this.t('full-scan-description')}</chameleon-typography
            >
          </div>
          <div class="drawer-buttons-section">
            <chameleon-typography
                    class="drawer-buttons-section__title"
                    variant="body-small-strong"
              >${this.t('virus-definition-database')}</chameleon-typography
            >
            <chameleon-button
              class="drawer-buttons-section__button"
              .disabled=${isDataLoading ||
              !this.selectionSummaryData?.validationData[CommandTypes.UPDATE_DEFINITIONS]}
              @click=${this.onSelectedUpdateDefinitionsClicked}
              variant="neutral"
              fullwidth
            >
              <chameleon-svg slot="start">${SVG_DOWNLOAD_FILLED}</chameleon-svg>
              ${this.t('update-definition')}
            </chameleon-button>
          </div>
          <chameleon-typography variant="body-small-strong"
            >${this.t('modify-protection')}</chameleon-typography
          >
          <chameleon-typography
                  gutter-bottom
                  variant="caption-medium"
                  color="type-color-secondary"
            >${this.t('modify-protection-description')}</chameleon-typography
          >
          <div class="enable-buttons-block">
            <chameleon-typography class="enable-buttons-block__title" variant="body-small"
              >${this.t('real-time-protection')}</chameleon-typography
            >
            <chameleon-button
              class="enable-buttons-block__button"
              .disabled=${isDataLoading ||
              !this.selectionSummaryData?.validationData[CommandTypes.ENABLE_RTP]}
              @click=${this.onSelectedEnableRTPClicked}
              variant="neutral"
            >
              ${this.t('enable')}
            </chameleon-button>
          </div>
          <div class="enable-buttons-block">
            <chameleon-typography class="enable-buttons-block__title" variant="body-small"
              >${this.t('firewall')}</chameleon-typography
            >
            <chameleon-button
              class="enable-buttons-block__button"
              .disabled=${isDataLoading ||
              !this.selectionSummaryData?.validationData[CommandTypes.ENABLE_FIREWALL]}
              @click=${this.onSelectedEnableFirewallClicked}
              variant="neutral"
            >
              ${this.t('enable')}
            </chameleon-button>
            <chameleon-button
              class="enable-buttons-block__button"
              .disabled=${isDataLoading ||
              !this.selectionSummaryData?.validationData[CommandTypes.DISABLE_FIREWALL]}
              @click=${this.onSelectedDisableFirewallClicked}
              variant="neutral"
            >
              ${this.t('disable')}
            </chameleon-button>
          </div>
          `}
        </div>
        <div class="clear-button-wrapper">
          <chameleon-button variant="neutral" fullwidth @click=${this.onClearSelectionClicked}>
            <chameleon-svg slot="start">${SVG_PRESENCE_BUSY_INACTIVE}</chameleon-svg>
            ${this.t('clear-selection')}
          </chameleon-button>
        </div>
      </synapse-drawer>
    `;
  }
}

if (!customElements.get(COMPONENT_TAG_NAME)) {
  customElements.define(COMPONENT_TAG_NAME, Drawer);
}

export default Drawer;
