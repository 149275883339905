import i18n from '../../services/app/i18n';

type TOption = {
  locales?: string;
  weekday?: 'short' | 'long' | 'narrow';
};

export const getLocaleDateString = (date: string | number, option: TOption = {}) => {
  const { locales = i18n.language || 'default', weekday } = option;

  return new Date(date).toLocaleDateString(locales, {
    weekday,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit',
  });
};
