import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter';
import '@getgo/chameleon-web/components/registerAll';
import { CustomElement } from 'components';
import { ensureStyles } from 'utils';
import './index.scss';


ensureStyles();

export default CustomElement;
