import { GOTO_RESOLVE_ROUTER_BASENAME } from 'utils/getRouterBasename/constants';

export const environments = {
  env: '#{Environment.Name}#',
  baseUrl: '#{frontendUrl}#',
  HTTPheaders: {
    'Content-type': 'application/json; charset=UTF-8',
  },
  routerBasename: GOTO_RESOLVE_ROUTER_BASENAME,
  frontendApiUrl: '#{frontendApiUrl}#',
  remoteExecutionJobReportApiUrl: '#{remoteExecutionJobReportApiUrl}#',
  automationFrontendApiUrl: '#{automationFrontendApiUrl}#',
  helpdeskApiUrl: '#{helpdeskApiUrl}#',
  telemetryConnectionString: '#{telemetryConnectionString}#',
  remoteExecutionFrontendApiUrl: '#{remoteExecutionFrontendApiUrl}#',
  zeroTrustApiUrl: '#{zeroTrustApiUrl}#',
  wingetSourceUrl: '#{wingetSourceUrl}#',
  wingetSourceName: '#{wingetSourceName}#',
  gotoAdminMicroTransactionUrl: '#{gotoAdminMicroTransactionUrl}#',
};
