import { useSyncExternalStore } from 'react';
import { TDevice } from 'types';
import { BehaviorSubject } from 'rxjs';

export const selectedDevicesSubject$ = new BehaviorSubject<TDevice[]>([]);

export const add = (devices: TDevice[]) => selectedDevicesSubject$.next([...devices]);

export const clear = () => selectedDevicesSubject$.next([]);

const useSelectedDevices = () => {
  const devices = useSyncExternalStore(
    (listener: () => void) => {
      const subscription = selectedDevicesSubject$.subscribe(listener);

      return () => subscription.unsubscribe();
    },
    () => selectedDevicesSubject$.value
  );

  return {
    state: devices,
    add,
    clear,
  };
};

export default useSelectedDevices;
