import { Button, EmptyState, Link, Typography } from '@getgo/chameleon-web-react-wrapper';
import { EncryptionOutlinedIcon } from '@getgo/chameleon-icons/react';
import { useTranslation } from 'react-i18next';
import { withLayout } from 'shared/HOCs';
import { openNewTab } from 'synapse-js/events/navigation';
import './EnableProtectionPage.scss';
import { ExternalPath } from 'app_constants/path';

const EnableProtectionPage = ({ enableProtection }: { enableProtection: () => void }) => {
  const { t } = useTranslation('EnableProtectionPage');

  const openSupportLink = () => openNewTab(ExternalPath.SUPPORT_ANTIVIRUS);

  const actions = (
    <>
      <Button variant="primary" size="medium" onClick={enableProtection}>
        {t('enable-protection-button')}
      </Button>
      <Link variant="body-small" onClick={openSupportLink}>
        {t('learn-more-link')}
      </Link>
    </>
  );

  return (
    <>
      <EmptyState
        className="empty-state"
        icon={<EncryptionOutlinedIcon style={{ color: 'var(--goto-icon-color-brand-default)' }} />}
        size="medium"
        title={t('title')}
        actions={actions}
      >
        <Typography variant="body-small" color="type-color-secondary">
          {t('body')}
        </Typography>
      </EmptyState>
    </>
  );
};

export default withLayout(EnableProtectionPage);
