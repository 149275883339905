import { useSyncExternalStore } from 'react';
import { auditTime, BehaviorSubject, combineLatest, filter } from 'rxjs';
import { selectedDevicesSubject$ } from 'observables/selectedDevices';
import { selectedGroupsSubject$ } from 'observables/selectedGroups';
import { clear as clearSelectionSummaryData } from 'observables/selectionSummaryData';

type DrawerState = {
  isOpen: boolean;
};

const initialState = {
  isOpen: false,
};

export const drawerSubject$ = new BehaviorSubject<DrawerState>(initialState);

const setOpened = () => {
  drawerSubject$.next({
    ...drawerSubject$.value,
    isOpen: true,
  });
};

const setClosed = () => {
  drawerSubject$.next(initialState);
  clearSelectionSummaryData();
};

combineLatest([selectedDevicesSubject$, selectedGroupsSubject$]).subscribe(
  ([selectedDevices, selectedGroups]) => {
    if (!selectedDevices.length && !selectedGroups.length) {
      setClosed();
    } else {
      setOpened();
    }
  }
);

export const drawerOpened = combineLatest([selectedDevicesSubject$, selectedGroupsSubject$]).pipe(
  auditTime(500),
  filter(([selectedDevices, selectedGroups]) => !!selectedDevices.length || !!selectedGroups.length)
);

const useDrawer = () => {
  const drawer = useSyncExternalStore(
    (listener: () => void) => {
      const subscription = drawerSubject$.subscribe(listener);

      return () => subscription.unsubscribe();
    },
    () => drawerSubject$.value
  );

  return {
    state: drawer,
    setClosed,
  };
};

export default useDrawer;
