import http from 'services/app/http';
import { QueryFunction } from '@tanstack/react-query';
import environment from 'environments';

const ANTIVIRUS = 'Antivirus';
const baseUrl = environment.helpdeskApiUrl;
const headers = { 'Client-Identifier': ANTIVIRUS };

type TQueryKey = readonly [string, { id: string }];
type TServicesResult = {
  services: { id: string; name: string; path: string }[];
};
type TCategoriesResult = {
  data: {
    categories: { id: string; name: string }[];
    service_priorities: { id: string; description: string; priority_level: number }[];
  };
};
type TCreateTicketParams = {
  serviceId: string;
  categoryId: string;
  priorityId: string;
  subject: string;
  summary: string;
};

export const getServices = async (): Promise<TServicesResult> => {
  return http.sendRequest({
    method: 'GET',
    baseUrl,
    url: '/dashboards/get_services',
  });
};

export const getServiceCategories: QueryFunction<TCategoriesResult, TQueryKey> = async ({
  queryKey,
}) => {
  return http.sendRequest({
    method: 'GET',
    baseUrl,
    url: `/services/${queryKey[1].id}.json?include=categories,service_priorities`,
  });
};

export const createTicket = async ({
  serviceId,
  categoryId,
  priorityId,
  subject,
  summary,
}: TCreateTicketParams) => {
  const body = {
    data: {
      type: 'incidents',
      attributes: {
        title: subject,
        source: ANTIVIRUS,
      },

      relationships: {
        service: {
          data: {
            type: 'service',
            id: serviceId,
          },
        },
        category: {
          data: {
            type: 'category',
            id: categoryId,
          },
        },
        priority: {
          data: {
            type: 'priority',
            id: priorityId,
          },
        },
        symptoms: {
          data: {
            'temp-id': ANTIVIRUS,
            type: 'symptoms',
            method: 'create',
          },
        },
      },
    },
    included: [
      {
        'temp-id': ANTIVIRUS,
        type: 'symptoms',
        attributes: {
          note: summary,
          note_type: 'Symptom',
        },
      },
    ],
  };

  return http.sendRequest({
    method: 'POST',
    baseUrl,
    url: '/incidents.json',
    headers,
    body,
  });
};
