import { TDevice } from 'types';
import { DeviceStatus, TDeviceStatus } from 'app_constants/status';

export const sortableStatus = (status: TDeviceStatus, device: TDevice) => {
  let order = 0;

  switch (status) {
    case DeviceStatus.IN_SESSION:
      order = 3;
      break;
    case DeviceStatus.ONLINE:
      order = device?.locked ? 1 : 2;
      break;
    case DeviceStatus.OFFLINE:
      order = 0;
      break;
    default:
      order = 0;
      break;
  }

  return [order, device.premium, device.lastSeen];
};
