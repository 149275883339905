import { ApiEndpoint } from 'app_constants/path';
import { Integration } from 'app_constants';
import http from 'services/app/http';
import { TCommandApiService, TDeviceValidationList } from 'types';
import telemetry from 'services/app/telemetry';
import { FirewallState } from 'app_constants/status';

export const quickScan: TCommandApiService = async ({ devices, groupIds }) => {
  telemetry.trackEvent(telemetry.events.RUN_QUICK_SCAN, { integration_used: Integration.OPSWAT });

  const deviceIds = devices.map(({ id }) => id);

  if (deviceIds.length || groupIds.length) {
    return http.post(`${ApiEndpoint.COMMANDS}/scan/default`, {
      devicesIds: deviceIds,
      groupsIds: groupIds,
    });
  }

  return http.post(`${ApiEndpoint.COMMANDS}/scan/default/company`);
};

export const fullScan: TCommandApiService = async ({ devices, groupIds }) => {
  telemetry.trackEvent(telemetry.events.RUN_FULL_SCAN, {
    integration_used: Integration.OPSWAT,
  });

  const deviceIds = devices.map(({ id }) => id);

  return http.post(`${ApiEndpoint.COMMANDS}/scan/full`, {
    devicesIds: deviceIds,
    groupsIds: groupIds,
  });
};

export const pathScan: TCommandApiService = async ({ devices, groupIds, path }) => {
  const deviceIds = devices.map(({ id }) => id);

  return http.post(`${ApiEndpoint.COMMANDS}/scan/path`, {
    devicesIds: deviceIds,
    groupsIds: groupIds,
    path: path || 'c/',
  });
};

export const updateDefinitions: TCommandApiService = async ({ devices, groupIds }) => {
  telemetry.trackEvent(telemetry.events.UPDATE_VIRUS_DEFINITION, {
    integration_used: Integration.OPSWAT,
  });

  const deviceIds = devices.map(({ id }) => id);

  return http.post(`${ApiEndpoint.COMMANDS}/update-definitions`, {
    devicesIds: deviceIds,
    groupsIds: groupIds,
  });
};

export const enableRTP: TCommandApiService = async ({ devices, groupIds }) => {
  telemetry.trackEvent(telemetry.events.ENABLE_REALTIME_PROTECTION, {
    integration_used: Integration.OPSWAT,
  });

  const deviceIds = devices.map(({ id }) => id);

  return http.post(`${ApiEndpoint.COMMANDS}/enable-rtp`, {
    devicesIds: deviceIds,
    groupsIds: groupIds,
  });
};

export const enableFirewall: TCommandApiService = async ({ devices, groupIds }) => {
  telemetry.trackEvent(telemetry.events.ENABLE_FIREWALL, {
    integration_used: Integration.OPSWAT,
  });

  const deviceIds = devices.map(({ id }) => id);

  return await http.put(`${ApiEndpoint.COMMANDS}/firewall-status`, {
    ids: {
      devicesIds: deviceIds,
      groupsIds: groupIds,
    },
    FirewallStatus: FirewallState.ENABLED,
  });
};

export const disableFirewall: TCommandApiService = async ({ devices, groupIds }) => {
  telemetry.trackEvent(telemetry.events.DISABLE_FIREWALL, {
    integration_used: Integration.OPSWAT,
  });

  const deviceIds = devices.map(({ id }) => id);

  return await http.put(`${ApiEndpoint.COMMANDS}/firewall-status`, {
    ids: {
      devicesIds: deviceIds,
      groupsIds: groupIds,
    },
    FirewallStatus: FirewallState.DISABLED,
  });
};

export const validateDevices: () => Promise<{ data: TDeviceValidationList }> = async () => {
  return http.post(`${ApiEndpoint.COMMANDS}/scan/default/company/validate`);
};
