import {
  GotoResolveApp,
  Job,
  Platform,
  RemoteExecutionService,
  HostSnapshot,
  Guid,
  HostRun,
  WingetInstallPackageStep,
  WingetAddSourceStep,
  RunDto,
} from '@goto/remote-execution';
import environments from 'environments';
import { DefaultUiFrameAdapter, Logger, MockUiFrameAdapter } from 'goto-zero-trust-vault';
import i18n from 'services/app/i18n';
import { TDevice } from 'types/device';
import { addLicense } from '../services/api/licenses/licenses';
import HttpRequestError from '../services/app/http/HttpRequestError';
import { AppUpdate, InstallJobReportService } from './install-job-report-service';
import { logErrorWithDescription } from 'services/app/telemetry/telemetry';

type DeviceSnapshot = Pick<TDevice, 'id' | 'deviceName' | 'hostName'>;

type DeviceFailure = { device: DeviceSnapshot; reason: 'licenses-exhausted' | Error };

export interface GotoAntivirusInstallRequest {
  devices: DeviceSnapshot[];
}

class LoggerService implements Logger {
  logInformation(message: string): void {
    console.info(message);
  }

  logWarning(error: string | Error): void {
    console.warn(error);
  }

  logError(error: string | Error): void {
    console.error(error);
  }
}

export class GotoAntivirusInstallService {
  private remoteExecutionService: RemoteExecutionService | undefined;
  private installJobReportService: InstallJobReportService | undefined;
  private readonly options = {
    zeroTrustApiUrl: environments.zeroTrustApiUrl,
    remoteExecutionFrontendApiUrl: environments.remoteExecutionFrontendApiUrl,
  };

  constructor() {
    const uiFrameAdapter =
      process.env.NODE_ENV === 'test'
        ? new MockUiFrameAdapter('companyId', 'userId', () => 'jwt')
        : new DefaultUiFrameAdapter();
    const logger = new LoggerService();
    this.remoteExecutionService = RemoteExecutionService.create(
      uiFrameAdapter,
      // TODO: Does this logger makes sense here?
      logger,
      this.options
    );
    this.installJobReportService = new InstallJobReportService();
  }

  async install(request: GotoAntivirusInstallRequest): Promise<{
    job?: RunDto;
    failedDevices: DeviceFailure[];
  }> {
    const { devices } = request;

    const addLicenseResults = await Promise.allSettled(
      devices.map(async (device) => {
        try {
          await addLicense(device.id);
        } catch (e) {
          if (e instanceof HttpRequestError && e.response.status === 409) {
            throw 'licenses-exhausted';
          }
          throw e;
        }
      })
    );

    const licensedDevices: DeviceSnapshot[] = [];
    const failedDevices: DeviceFailure[] = [];
    for (let i = 0; i < devices.length; i++) {
      const result = addLicenseResults[i];
      if (result.status === 'fulfilled') {
        licensedDevices.push(devices[i]);
      } else {
        failedDevices.push({ device: devices[i], reason: result.reason });
      }
    }

    if (licensedDevices.length === 0) {
      return { failedDevices };
    }

    const wingetAddSource: WingetAddSourceStep = {
      type: 'wingetAddSource',
      version: 1,
      arguments: [
        {
          key: 'sourceName',
          value: environments.wingetSourceName,
        },
        {
          key: 'sourceUrl',
          value: environments.wingetSourceUrl,
        },
      ],
    };

    const wingetInstallPackage: WingetInstallPackageStep = {
      type: 'wingetInstallPackage',
      version: 1,
      arguments: [
        {
          key: 'packageId',
          value: 'goto-resolve.endpoint-protection',
        },
        {
          key: 'packageSource',
          value: environments.wingetSourceName,
        },
        {
          key: 'packageVersion',
          value: null,
        },
      ],
    };

    const job: Job = {
      displayName: GotoAntivirusInstallService.name,
      steps: [wingetAddSource, wingetInstallPackage],
      devices: licensedDevices.map(this.mapDeviceToHostSnapshot),
      platform: Platform.Windows,
      app: GotoResolveApp.Antivirus,
    };

    const response = await this.run(job);

    if (!this.installJobReportService) {
      logErrorWithDescription('InstallJobReportService is not instantiated.');
    } else {
      this.installJobReportService.reportJob({
        remoteExecutionJobId: response.id,
        appUpdateInfo: {
          deviceIdToAppUpdate: licensedDevices.reduce((acc, device) => {
            acc[device.id] = [
              {
                id: 'goto-resolve.endpoint-protection',
                name: 'GotoResolve Endpoint Protection',
                source: environments.wingetSourceName,
                targetVersion: 'latest',
              },
            ];

            return acc;
          }, {} as { [key: string]: AppUpdate[] }),
        },
      });
    }

    return {
      job: response,
      failedDevices,
    };
  }

  async status(jobId: Guid, deviceId: string): Promise<HostRun | undefined> {
    if (!this.remoteExecutionService) {
      return;
    }
    const hostRuns = await this.remoteExecutionService.getHostRuns(jobId);

    return hostRuns.find((r) => r.hostSnapshot.hostId === deviceId);
  }

  private mapDeviceToHostSnapshot({ id, hostName, deviceName }: DeviceSnapshot): HostSnapshot {
    return {
      hostId: id,
      hostName,
      name: deviceName,
    };
  }

  private async run(job: Job): Promise<RunDto> {
    if (!this.remoteExecutionService) {
      throw new Error('RemoteExecutionService is not instantiated.');
    }

    return await this.remoteExecutionService.run(job, i18n.getFixedT(null, 'zeroTrust')('install'));
  }
}

export default new GotoAntivirusInstallService();
