import http from 'services/app/http';
import { TDevice, TselectionSummaryData } from 'types';

export const getSelectionSummary: ({
  devices,
  groupIds,
  isGtreEpp,
}: {
  devices: TDevice[];
  groupIds: (string | null)[];
  isGtreEpp?: boolean;
}) => Promise<TselectionSummaryData> = async ({ devices, groupIds, isGtreEpp }) => {
  const deviceIds = devices.map(({ id }) => id);
  const devicesSummaryEndpoint = isGtreEpp !== undefined ? `/devices/summary?isGtreEpp=${isGtreEpp}` : '/devices/summary';

  return http.post(devicesSummaryEndpoint, {
    devicesIds: deviceIds,
    groupsIds: groupIds,
  });
};
